<div id="header-container">
  <div class="search-wrapper">
    <span class="material-icons" id="search">
      search
    </span>
    <input type="text" placeholder="Search.." name="search" (keyup)="doFilter($event.target.value)" autocomplete="off">
  </div>
  <div class="paginator-wrapper">
    <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <div *ngFor="let column of tableData['name']; let i = index">
    <ng-container [matColumnDef]="tableData['name'][i]">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{tableData['display'][i]}} </th>
      <td mat-cell *matCellDef="let element" style="white-space: pre-line;">

        <span *ngIf="groupsToBeHyperlinked(tableData['name'][i])">
          <a [attr.href]="element['link']">
            {{element[tableData['name'][i]]}}
          </a>
        </span>

        <span *ngIf="groupsToBeRouterLinked(tableData['name'][i], element[tableData['name'][i]])">
          <a [routerLink]="[element['routerLink']]">
            {{element[tableData['name'][i]]}}
          </a>
        </span>
        
        <span *ngIf=" !groupsToBeHyperlinked(tableData['name'][i]) && !groupsToBeCondensed1(tableData['name'][i]) && !groupsToBeCondensed2(tableData['name'][i]) && !groupsToBeRouterLinked(tableData['name'][i], element[tableData['name'][i]])">{{element[ tableData['name'][i] ]}} </span>
        
        <span *ngIf=" groupsToBeCondensed1(tableData['name'][i]) ">{{ condenseText ( element[ tableData['name'][i] ] ) }} 
          <mat-icon id="copyButton" (click)="copyText(element[ tableData['name'][i] ])">content_copy</mat-icon>
        </span>
        
        <span *ngIf="groupsToBeCondensed2(tableData['name'][i])" class="hover-container">
          {{ condenseText(element[tableData['name'][i]]) }}
          <div class="hover-box">{{element[ tableData['name'][i] ]}}</div>
      </span>
      

      </td>
    </ng-container>
  </div>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<br />
<br />
