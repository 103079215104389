import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Papa from 'papaparse';

interface Species {
  taxonomyId: string;
  speciesName: string;
  commonName: string;
}

@Component({
  selector: 'app-p3db-cascader-input-search',
  templateUrl: './p3db-cascader-input-search.component.html',
  styleUrls: ['./p3db-cascader-input-search.component.css']
})
export class P3dbCascaderInputSearchComponent implements OnInit {
  constructor(private http: HttpClient) { }
  
  public inputValue='';
  public currentLabel=[]
  public defaultValue = ''
  @Input()width='250px'
  @Input()labels: Array<any>;
  @Output() handleEvent = new EventEmitter();
  inputWidth:String;
  public isProteinNameSearch: boolean = false;
  public secondaryQueryType: string = '';
  public secondaryQueryValue: string = '';
  public secondaryQueryOptions: Array<{ label: string, value: string }> = [
    { label: 'SpeciesID', value: 'SpeciesID' }
  ];
  
  // Species-related properties
  public speciesList: Species[] = [];
  public filteredSpecies: Species[] = [];
  public showSpeciesDropdown: boolean = false;

  ngOnInit(): void {
    this.currentLabel = this.labels[0].value;
    this.inputValue = this.labels[0].defaultValue;
    this.inputWidth='100%';
    this.isProteinNameSearch = this.currentLabel.includes('Protein Name Search');
    this.secondaryQueryType = 'SpeciesID';
    this.loadSpeciesData();
  }

  loadSpeciesData(): void {
    const csvPath = 'assets/species.csv';
    this.http.get(csvPath, { responseType: 'text' }).subscribe(
      (data) => {
        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            this.speciesList = result.data.map((row: any) => ({
              taxonomyId: row['Taxonomy ID'],
              speciesName: row['Species Name'],
              commonName: row['Common Name']
            }));
            console.log('Species data loaded:', this.speciesList);
          }
        });
      },
      error => console.error('Error loading species data:', error)
    );
  }

  onSecondaryQueryInput(value: string): void {
    if (!value?.trim()) {
      this.filteredSpecies = [];
      this.showSpeciesDropdown = false;
      return;
    }

    const query = value.toLowerCase().trim();
    this.filteredSpecies = this.speciesList
      .filter(species =>
        species.taxonomyId.toLowerCase().includes(query) ||
        species.speciesName.toLowerCase().includes(query) ||
        (species.commonName && species.commonName.toLowerCase().includes(query))
      )
      .sort((a, b) => {
        // Prioritize matches that start with the query
        const aStarts = 
          a.taxonomyId.toLowerCase().startsWith(query) ||
          a.speciesName.toLowerCase().startsWith(query) ||
          (a.commonName && a.commonName.toLowerCase().startsWith(query));
        const bStarts = 
          b.taxonomyId.toLowerCase().startsWith(query) ||
          b.speciesName.toLowerCase().startsWith(query) ||
          (b.commonName && b.commonName.toLowerCase().startsWith(query));
        
        if (aStarts && !bStarts) return -1;
        if (!aStarts && bStarts) return 1;
        return 0;
      })
      .slice(0, 10); // Limit to 10 results

    this.showSpeciesDropdown = this.filteredSpecies.length > 0;
  }

  selectSpecies(species: Species): void {
    this.secondaryQueryValue = species.taxonomyId; // Use taxonomy ID as the value
    this.showSpeciesDropdown = false;
  }

  handle(){
    const searchParams = {
      value: this.inputValue,
      label: this.currentLabel.toString(),
      secondaryQueryType: this.isProteinNameSearch ? this.secondaryQueryType : null,
      secondaryQueryValue: this.isProteinNameSearch && this.secondaryQueryValue ? this.secondaryQueryValue : null
    };
    this.handleEvent.emit(searchParams);
  }

  onLabelChange() {
    this.isProteinNameSearch = this.currentLabel[0] == 3;
    if(this.currentLabel[0]=="1"){
      this.inputValue=this.labels[0].defaultValue
    }else if(this.currentLabel[0]=='2'){
      console.log("the labels are : ",this.labels," and the current label is : ",this.currentLabel);
      const selectedChildIndex = parseInt(this.currentLabel[1]) - 1;
      if (selectedChildIndex >= 0 && selectedChildIndex < this.labels[1].children.length) {
        this.inputValue = this.labels[1].children[selectedChildIndex].defaultValue;
      }
    }else if(this.currentLabel[0]=='3'){
      // Protein Name Search (value '3') is at index 3
      this.inputValue = this.labels[3].defaultValue; // 'ras-related protein'
    }else if(this.currentLabel[0]=='4'){
      // Gene Symbol Search (value '4') is at index 2
      this.inputValue = this.labels[2].defaultValue; // 'RAB1'
    }
  }
}
