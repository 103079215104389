<br>
<mat-card id="component-container" *ngIf="!pubmedID">
  <!-- Dataset View -->
  <mat-card id="title-container" style="margin-top: 10px;">
    <!-- Loading State -->
    <div class="loading-container" *ngIf="!ELEMENT_DATA && !requestError">
      <div class="loading-content">
        <p>Fetching dataset data...</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <!-- Error Message -->
    <div *ngIf="requestError">
      <p style="text-align: center; margin: 20px 0"><b>{{ requestError }}.</b></p>
      <p style="text-align: center">Please try refreshing the page.</p>
    </div>

    <!-- Dataset Table -->
    <app-table *ngIf="ELEMENT_DATA && !requestError" [tableData]="ELEMENT_DATA"></app-table>
  </mat-card>
</mat-card>

<mat-card id="component-container" *ngIf="pubmedID">
  <!-- PubMed View -->
  <div class="pubmed-container">
    <div id="title-container">
      <button id="back-button" mat-raised-button color="primary" (click)="onBackClick()">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
      <div id="title-container-inner">
        <h1 id="title">Pubmed ID: {{pubmedID}}</h1>
      </div>
    </div>

    <div class="loading-container" *ngIf="!PUBMED_DATA && !ELEMENT_DATA">
      <div class="loading-content">
        <p>Fetching the paper data...</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <div *ngIf="PUBMED_DATA">
      <app-table [tableData]="PUBMED_DATA"></app-table>
    </div>

    <div *ngIf="ELEMENT_DATA">
      <app-table [tableData]="ELEMENT_DATA"></app-table>
    </div>
  </div>
</mat-card>
<br>
