<!-- <p>universal-search works!</p> -->
<!-- <app-toolbar></app-toolbar> -->

<!-- <mat-card class="container-card" > -->
    <!-- <mat-card class="background-card" > -->

        <div class="search-div" style = "margin-top: 5%; margin-bottom: 3%;" >
                <mat-card class="searchcard" >
                <!-- <mat-card class> -->
                    <mat-card id="title-container">
                        <h2 id="title">ID mapping</h2>
                      </mat-card>
            
                    <mat-card-content>
                        <mat-card>
                                <mat-form-field appearance="fill">
                                    <mat-label>Search Parameter</mat-label>
                                    <mat-select [(value)]="selected_option" >
                                    <mat-option *ngFor="let option of select_options" value = {{option}}> {{ option }} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>
                                <mat-grid-list cols="10" rowHeight="2:1">
                                    <mat-grid-tile colspan="10" style="width: 100%;">
                                        <mat-form-field style ="width: 100%;" appearance = "fill">
                                            <input  matInput 
                                                    [matAutocomplete]="uniprot" 
                                                    [formControl]="identifierControl"
                                                    type="search" 
                                                    [(ngModel)] = "query" 
                                                    placeholder={{selected_option}} 
                                                    name="searchField" 
                                                    (keyup)="lazy_load($event)" 
                                                    style="font-size: 20px;">

                                            <mat-autocomplete #uniprot="matAutocomplete" position="below">
                                                <mat-option *ngFor="let item of search_records" [value]="item[selected_option]"> {{ item[selected_option] }} </mat-option>
                                            </mat-autocomplete>
                                            
                                            
                                        </mat-form-field>


                                        <button mat-icon-button matSuffix (click) = user_search()>
                                            <mat-icon style="font-size: 25px;">search</mat-icon>
                                        </button>
                                    </mat-grid-tile>
                                </mat-grid-list>
                        </mat-card>
                        <mat-card *ngIf="showWait" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
                            <nz-spin *ngIf="showWait" nzSimple [nzSize]="'large'"></nz-spin>
                          </mat-card>
                        <mat-card *ngIf="noData" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
                            <h1>No search results for {{this.query}} ...</h1>
                          </mat-card>
                        <mat-card *ngIf="listOfDataProteinIDs.length" style="margin-top: 10px; overflow-x: scroll;">
                            <h3 id="title">Protein Details</h3>
                        </mat-card>
                        <mat-card *ngIf="listOfDataProteinIDs.length" id="title-container" style="margin-top: 10px; overflow-x: scroll;">
                        <p3dbTable [columnKeys]="columnKeysProteinIDs" [dataSourceShow]="listOfDataProteinIDs" [searchIndex]="searchIndex"
                            [hasIcon]="true" [thisTip]="'This is not a Phosophory Protien'" [iconTip]="'This is Phosphorylation Protein'"
                            (handleCellClick)="handleCellClick($event)"></p3dbTable>
                        </mat-card>
                                
                    </mat-card-content>
                </mat-card>

        </div>

            <!-- <div >
            <mat-card *ngIf = "results" > 
                <table mat-table [dataSource] = "results">
                    <ng-container matColumnDef = "headers">
                        <th mat-header-cell *matHeaderCellDef> <h1> ID </h1> </th>
                        <td mat-cell *matCellDef="let result"> <h3><b>{{ result.key }}</b></h3> </td>

                    </ng-container>
                    <ng-container matColumnDef = "data">
                        <th mat-header-cell *matHeaderCellDef> <h1> Value </h1> </th>
                        <td mat-cell *matCellDef="let result of results" class="align-middle"> 
                            <h3>{{ result.value }}</h3>

                        </td>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="datatable" style="vertical-align:middle;"></tr>
                    <tr mat-row *matRowDef="let element; columns: datatable;"
                        class="example-element-row"></tr>
                </table>

                
            </mat-card>
        </div> -->




    <!-- </mat-card> -->
<!-- </mat-card> -->
         
        
