<mat-card id="component-container" style="margin-top: 5%; margin-bottom: 3%;">

  <mat-card>
    <h2 id="title">KIC (Kinase Client) Assay Network</h2>
  </mat-card>
  


  <mat-card class="card" style="margin-top: 10px;">
    <pre style="overflow-x:hidden;text-align: center;">
      <mat-form-field>
        <mat-label>Experiment ID</mat-label>
        <mat-select matInput [(value)]="experimentFilter.kicExperimentId">
          <mat-option value='Nagib 2007(Thelen lab)'>Nagib 2007(Thelen lab)</mat-option>
          <mat-option value='Nagib 2021(Thelen lab)'>Nagib 2021(Thelen lab)</mat-option>
          <mat-option value='Gabriel 2024(Thelen lab&Stacy lab)'>Gabriel 2024(Thelen lab&Stacy lab)</mat-option>
          <mat-option value='all'>All</mat-option>
        </mat-select>
      </mat-form-field>
    </pre>
  <div>
    <app-cytoscape-network [optionalData]="experimentFilter" [cytoData]="ELEMENT_DATA"></app-cytoscape-network>
  </div>
  
  </mat-card>

</mat-card>
