<mat-card class="base">
    <div class="page-title-bar">
        <p class="page-title">ASK AI</p>
    </div>
    <div style="margin-top: 1%;">
        <mat-tab-group [@.disabled]="true">
            <mat-tab>
                <ng-template mat-tab-label>
                    AI Q&A
                </ng-template>
                <app-message-ai></app-message-ai>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    ChatGPT - ImageUpload
                </ng-template>
                <app-chat-gpt-image-upload></app-chat-gpt-image-upload>
            </mat-tab>
            
            
        </mat-tab-group>
    </div>
</mat-card>