import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import cytoscape from 'cytoscape';
import euler from 'cytoscape-euler';
import dagre from 'cytoscape-dagre';
import spread from 'cytoscape-spread';
import cola from 'cytoscape-cola';
import { element } from 'protractor';
import { discardPeriodicTasks } from '@angular/core/testing';
import { CytographAdditionalData, ExtendableNode } from 'src/app/interfaces/cytograph-additional-data';
import { DoCheck, KeyValueDiffers } from '@angular/core';
import { KicnetworkComponent } from 'src/app/pages/kicnetwork/kicnetwork.component';
import { Http2ServerRequest } from 'http2';
import { HttpClient } from '@angular/common/http';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { environment } from 'src/environments/environment';
import { ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';





@Component({
  selector: 'app-cytoscape-network',
  templateUrl: './cytoscape-network.component.html',
  styleUrls: ['./cytoscape-network.component.scss'],
  
})
export class CytoscapeNetworkComponent implements OnInit, DoCheck {
  @Input() cytoData;
  @Input() optionalData?: CytographAdditionalData;
  differ: any;
  @ViewChild('warningSearchDialog') warningSearchDialog: TemplateRef<any>;
 
  hover_edge_data=[];
  hover_data =[];
  graph;  
  graphElements;
  details = [];
  layouts = [
    { value: 'Euler', ref: euler },
    { value: 'Dagre', ref: dagre },
    { value: 'Spread', ref: spread },
    { value: 'Cola', ref: cola,
  
    ready: e => {
        e.cy.fit()
        e.cy.center()}}
    

  ];
  selected = {
    name: ['id', 'group', 'value 1', 'value 2'],
    display: ['ID', 'Group', 'Value 1', 'Value 2'],
    data: [],
  };
  showSelected = false;
  showProteinInfo = false;
  isPtmViewer = true;
  showNodeInfo = false;
  searchForm;
  filterForm;
  filters = [];
  styleForm;
  styles = [];


  // these are used for the filter form
  edgeKeys;
  nodeKeys;

  cytoDataFull;
  // sayeeraField;
  clickedNodeId: any;
  clickedNode: any;
  originalElements: any;
  isResetEnabled = false;
  currentSearchLabel: string = 'Search Kinase Client (example: AT5G39440)...';
  showExperimentData = false;

  constructor(private fb: FormBuilder, private differs: KeyValueDiffers , private kc: KicnetworkComponent,http: HttpClient,private dialog: MatDialog) {
    this.layouts.forEach((x) => {
      cytoscape.use(x['ref']);
    });

    this.differ = differs.find({}).create();
    // this.kc.getData();
    console.log("Cytoscape constructor called");
    // this.sayeeraField= this.kc.sayeeraField;
    console.log('cytoData in Constructor',this.cytoData);
  }

  ngOnInit(): void {
    this.populateGraph();
    this.buildFilterForm();
    this.buildStyleForm();
    this.addDefaultStlyes();
    this.buildSearchForm();
    this.searchForm.get('searchType').valueChanges.subscribe(value => {
      this.updateSearchLabel(value);
    });
  }

  ngOnChanges(): void {
    this.populateGraph();
  }

  
  changeLayout(layout) {
    this.graph
      .layout({
        name: layout.toLowerCase(),
      })
      .run();
  }
  
  
  

  // we need something more granular to check if our optiondata changes
  ngDoCheck() {
    var changes = this.differ.diff(this.optionalData);
    if (changes && this.optionalData && this.cytoData) {
      this.handleParentData();
    }
      
  }

  // temporary way to remove added extendablenodes
  addedNode: string = "";
  addedEdge: string = "";

  // given the proper structure, we can render additional nodes from
  // the data for an extendable node
  renderExtendableNode(node: ExtendableNode, details: any[]) {
    
    if (this.addedNode && this.addedEdge) {
      this.graph.remove('node[id = "'+this.addedNode+'"]');
      this.graph.remove('node[id = "'+this.addedEdge+'"]');
    }

    let connectedNodeId = "";
    details.forEach(e => {
      if (e.title == "Tair Id")
        connectedNodeId = e.detail;
    })

    this.addedNode = node.nodeTitle;
    this.addedEdge = node.nodeTitle + connectedNodeId;
    
    this.graph.add([{
      group: "nodes", 
      // we need tair_id because that's the label, it is not actually
      // a tair id
      data: {
        id: node.nodeTitle, tair_id: node.nodeTitle, ...node.data
      }
    }]);

    this.graph.add([{
      group: "edges", 
      data: {
        id: node.nodeTitle + connectedNodeId, source: connectedNodeId, target: node.nodeTitle, width: 5
      }
    }]);
  }

  //Updates the selected array to contain the elements selected on the graph
  updateSelected() {

    let elements = this.graph.elements('node:selected');
    let edge = this.graph.elements('edge:selected');
    
    

    elements.forEach((element) => {
      let newDetails = JSON.parse(JSON.stringify(element.data()));
      console.log("the newDetails data is as follows : ",newDetails);
      // this.sayeeraField = newDetails["sayeera"];
      console.log("sayeera is : ",newDetails["sayeera"]);
      delete newDetails["sayeera"];
      for(let key in newDetails){      
        let key_upper = key[0].toUpperCase() + key.slice(1);
        key_upper = key_upper.replace(/_/g," ");
        let key_new = "";

        for(let item of key_upper.split(" ")){
          key_new += (item[0].toUpperCase() + item.slice(1)+" ");
        }

        key_new = key_new.substring(0, key_new.length-1)
        newDetails[key_new] = newDetails[key];
        delete newDetails[key]
      }

      if(newDetails["Network Type"] == "kic"){
        
        if(newDetails.Family){
          delete newDetails["In Vitro Phosphosite"];
          delete newDetails["In Vivo Phosphosite"];
          delete newDetails["Id"];
          delete newDetails["Width"];
          delete newDetails["Network Type"];
        }else{
          delete newDetails["Family"];
          delete newDetails["Substrate Count"];
          delete newDetails["Id"];
          delete newDetails["Width"];
          delete newDetails["Network Type"];
        }
      }else if(newDetails["Network Type"] == "ppi"){
        delete newDetails["Network Type"];
        delete newDetails["Id"];

      }
      
      this.showDetails(newDetails);

    });

    edge.forEach((element) => {

      let newDetails = JSON.parse(JSON.stringify(element.data()));
      
      for(let key in newDetails){      
        let key_upper = key[0].toUpperCase() + key.slice(1);
        key_upper = key_upper.replace(/_/g," ");

        let key_new = "";
        for(let item of key_upper.split(" ")){
          key_new += (item[0].toUpperCase() + item.slice(1)+" ");
        }

        key_new = key_new.substring(0, key_new.length-1)
        newDetails[key_new] = newDetails[key];
        delete newDetails[key];
      }
      if(newDetails["Network Type"] == "kic"){
        delete newDetails["Avg No Peptide"];
        delete newDetails["Avg No Phosphopeptide"];
        delete newDetails["Network Type"];
        delete newDetails["Width"];
        delete newDetails["Id"];
        delete newDetails["Source"];
        delete newDetails["Target"];
      }else if(newDetails["Network Type"] == "ppi"){
        delete newDetails["Network Type"];
        delete newDetails["Source"];
        delete newDetails["Id"];
        delete newDetails["Target"];
      }
      console.log('i am here',newDetails);
      this.showExperimentData=true;
      this.showDetails(newDetails);
    });
  }

  showDetails(e) {

    let element = document.getElementById('detail_info') as HTMLDivElement;
    this.details = [];
    for (let key in e) {

      // we need to fix the names of our experimental data fields
      if (key == "Experiment data") {
        let newDetail = {};
        let newData = JSON.parse(e[key]);
        console.log("the newData is : ",newData);

        for (let expKey in newData) {
          const words = expKey.split('_');
          words.forEach((e, i) => {words[i] = e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()});
          
          newDetail[words.join(' ')] = newData[expKey];
        }
        this.details.push({
          title: key,
          detail: newDetail,
        });

        console.log("details data : ",this.details);
      }
      if (key=="Tair Id"){
        this.details.push({
          title:"Protein ID",
          detail:e[key],
        });
      }
      else {
        this.details.push({
          title: key,
          detail: e[key],
        });
      }

    }
  }
  // 
  populateGraph() {
    console.log('populateGraph CytoData',this.cytoData)
    if (this.cytoData != null) {
      // Resize nodes for kic assay
      console.log('entered populate Graph')
      if (this.cytoData.elements.nodes[0].data.network_type == 'kic')
        this.substrateRecount();
  
      if (this.cytoDataFull == null)
        this.cytoDataFull = JSON.parse(JSON.stringify(this.cytoData));
      this.originalElements = JSON.parse(JSON.stringify(this.cytoData.elements));
      this.cytoData['container'] = document.getElementById('cy');
      const options = {
        maxZoom: 2,
        minZoom: 0.3,
        autoResize: true,
      };
      console.log('cytoData',this.cytoData);
      this.graph = cytoscape({ ...this.cytoData, ...options });
      

      console.log("The graph is : ",this.graph);
      this.graph
        .layout({
          name: 'cola',
          // animate: true,
          // infinite: true,
          // fit: true,
        })
        .run();
  
      this.graphElements = this.graph.elements().clone();
  
      // Construct keys for nodes/edges
      this.nodeKeys = Object.keys(this.cytoData.elements.nodes[0].data);
      this.edgeKeys = Object.keys(this.cytoData.elements.edges[0].data);
  
      // We can't filter on this... yet
      this.edgeKeys.splice(this.edgeKeys.indexOf("experiment_data"), 1);
      
      // Add click event listener to item detail value
      // this.graph.on('tap', 'node', (event) => {
      //   const clickedNode = event.target;
      //   this.clickedNodeId = clickedNode.id(); // Store clicked node ID
      //   const clickedItemDetailValue = clickedNode.data().item_detail; // Get item detail value of clicked node
  
      //   if (clickedItemDetailValue) {
      //     this.onClickItemDetail(clickedItemDetailValue);
      //     {clickedItemDetailValue && console.log(1)}
      //   }
      // });

      // Add click event listener to nodes
      this.graph.on('tap', 'node', (event) => {     
        const clickedNode = event.target;
        this.displaySubNetwork(clickedNode.id());
      });
      

     this.setupEventListeners_mousehover()
    }
  }
  setupEventListeners_mousehover() {
    this.showExperimentData=false;
  this.graph.on('mouseover', 'node', (event) => {
    let node = event.target;
    console.log("Mouseover on node: ", node.id());
    this.details=[]
    this.showNodeDetails(node , 'node');
  });
  this.graph.on('mouseover', 'edge',(event)=>{
    let edge=event.target;
    console.log('mouse hover edge is ', edge);
    this.details=[]
    this.showNodeDetails(edge, 'edge');
  });

  this.graph.on('mouseout', 'node', () => {
    console.log("Mouseout on node");
    this.hideNodeDetails();
  });
  this.graph.on('mouseout','edge', ()=>{
    this.hideNodeDetails();
  })
  }
  hideNodeDetails() {
    let tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
    this.hover_data=[];
    this.hover_edge_data=[];
  }
  showNodeDetails(data: any, type: any) {
    this.showExperimentData=false;
    this.hover_data=[]
    this.hover_edge_data=[]
    if(type=='node'){
      console.log('node',data)
      let content = `
      <b>ID: </b>${data.id()}<br/>
      <b>Description: </b>${data.data('description')}`; 
      console.log('content for tooltip:', content);
      let keys =['description','family','network_type','substrate_count','type']
      for (let k in keys){
        if (keys[k]=='substrate_count'){
          this.hover_data.push({
            title: 'Substrate Count',
            detail: data.data(keys[k]),
          });
        }
        else {
          this.hover_data.push({
            title: keys[k],
            detail: data.data(keys[k]),
          });
        }
      }
      console.log('hover_data:',this.hover_data);
      let tooltip = document.getElementById('tooltip'); // Make sure this element exists in your HTML

      if (tooltip) {
        tooltip.innerHTML = content;
        tooltip.style.display = 'block';
        let position = data.renderedPosition(); // Get the rendered position of the node
        tooltip.style.top = position.y + 20 + 'px'; // Adjust `20` for tooltip offset if needed
        tooltip.style.left = position.x + 'px';
      }
    }
    else{
      let content = `
      <b>Source: </b>${data.data('source')}<br/>
      <b>target: </b>${data.data('target')}`;
      console.log('content for tooltip:', content);
      let keys =['source','target','In_Vitro_Sequence','Phosphorylated_Percentage','network_type'];
      
      for (let k in keys){
        this.hover_edge_data.push({
          title: keys[k],
          detail: data.data(keys[k]),
        });
      }
        console.log('hover edge data is:', this.hover_edge_data);
        let tooltip = document.getElementById('tooltip'); // Make sure this element exists in your HTML

      if (tooltip) {
        tooltip.innerHTML = content;
        tooltip.style.display = 'block';
        let position = data.renderedPosition(); // Get the rendered position of the node
        tooltip.style.top = position.y + 20 + 'px'; // Adjust `20` for tooltip offset if needed
        tooltip.style.left = position.x + 'px';
      }
      }
    }
  

  
  displaySubNetwork(nodeId: string) {
    this.details=[]
    this.hover_edge_data=[]
    //logic to filter nodes and edges based on the clicked node
    console.log('nodeID',nodeId);

    this.isResetEnabled = true;

    console.log('Display Sub Network called with Node',nodeId);
    const connectedEdges = this.graph.elements().edges().filter(edge => edge.source().id() === nodeId || edge.target().id() === nodeId);

  // Retrieve all connected node IDs from the edges
    const connectedNodeIds = connectedEdges.map(edge => [edge.source().id(), edge.target().id()]).flat();
    connectedNodeIds.push(nodeId); // Include the clicked node itself

    // Filter nodes to include only those that are connected
    const subNetworkNodes = this.graph.elements().nodes().filter(node => connectedNodeIds.includes(node.id()));

    // Combine nodes and edges into one collection for the sub-network
    const subNetworkElements = subNetworkNodes.union(connectedEdges);

    // Clear existing elements and display only the sub-network
    this.graph.elements().remove();
    this.graph.add(subNetworkElements);
    this.graph.layout({
      name: 'cola', // Use any layout that suits your visual needs
      fit: true
    }).run();

    this.setupEventListeners_mousehover();
  }

  resetNetwork() {
    this.isResetEnabled = false;
    this.graph.elements().remove();
    window.location.reload();
  }
  
  getGraphData(){
    return this.graph.elements().map(node => node._private.data);
  }

  performSearch() {
    let searchType = this.searchForm.get('searchType').value;
    let searchvalue = this.searchForm.get('searchValue').value;
    let SearchedNodeId = this.getGraphData().filter(item => item[searchType]?.toLowerCase().includes(searchvalue.toLowerCase()));
    console.log('searchedNodeID',SearchedNodeId);

    if(SearchedNodeId.length){
      this.displaySubNetwork(SearchedNodeId[0].id);
    }
    else{
      this.openDialogWithRef(this.warningSearchDialog)
    }
    this.searchForm.reset();
  }

  clearInput(inputElement: HTMLInputElement): void {
    inputElement.value = ''; // Clears the input field
  }
  openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  // onClickItemDetail(clickedItemDetailValue: string) {
  //   this.graph.nodes().lock(); // Lock all nodes to prevent further clicks during processing
  
  //   const existingNode = this.graph.$(`#${clickedItemDetailValue}`);
  //   if (!existingNode.empty()) {
  //     // Node with the same ID already exists, do not add new node and edge
  //     this.graph.nodes().unlock();
  //     return;
  //   }
  
  //   this.graph.add({
  //     group: 'nodes',
  //     data: {
  //       id: clickedItemDetailValue, // Set node ID as clicked item detail value
  //       // label: clickedItemDetailValue, // Set display text as clicked item detail value
  //     },
  //     style: {
  //       label: clickedItemDetailValue, // Set label as clicked item detail value
  //       'background-color': 'lightblue', // Set background color for the node
  //       color: 'black', // Set text color for the node
  //       'text-valign': 'center', // Set vertical alignment of the label
  //       'text-halign': 'center', // Set horizontal alignment of the label
  //       'text-wrap': 'wrap', // Enable text wrapping for long labels
  //       'text-max-width': '80px', // Set the maximum width of the label
  //       width: '100px', // Set the width of the node
  //       height: '50px', // Set the height of the node
  //       shape: 'roundrectangle', // Set the shape of the node
  //       'border-width': '2px', // Set the border width of the node
  //       'border-color': 'black', // Set the border color of the node
  //     },
  //   });
    
  
  //   // Add new edge with source as clicked item detail value and target as clicked node ID
  //   this.graph.add({
  //     group: 'edges',
  //     class: 'newEdge',
  //     style: {
  //       width: 2,
  //       'target-arrow-shape': 'triangle', // Set arrow shape for the target end of the edge
  //       'target-arrow-color': 'black', // Set arrow color for the target end of the edge
  //     },
  //     data: {
  //       id: clickedItemDetailValue + '-edge',
  //       source: clickedItemDetailValue, // Set source as clicked item detail value
  //       target: this.clickedNodeId, // Set target as clicked node ID (make sure this is defined)
  //     },
     
  //   });
    
  // // Apply cola layout to automatically position the nodes
  // const layout = this.graph.layout({
  //   name: 'cola',
  //   animate: true,
  //   randomize: false, // Set to true if you want random initial node positions
  //   avoidOverlap: true, // Enable overlap avoidance
  //   handleDisconnected: true, // Handle disconnected components separately
  //   nodeSpacing: 50, // Set the desired node spacing
  //   edgeLength: 150, // Set the desired edge length
  // });

  
  //   // Update layout after adding new node and edge
  //   layout.run();

  //   layout.on('layoutstop', () => {
  //     this.graph.nodes().unlock(); // Unlock all nodes after layout is complete
  //   });
  // }
  
  
  alphaNumericValidator(control: FormControl): ValidationErrors | null {
    let ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_/ ]*$/;
    let ALPHA_NUMERIC_VALIDATION_ERROR = {
      alphaNumericError: 'Only alphanumeric values are allowed',
    };

    return ALPHA_NUMERIC_REGEX.test(control.value)
      ? null
      : ALPHA_NUMERIC_VALIDATION_ERROR;
  }

  //builds the angular form for the filters section
  buildFilterForm() {
    this.filterForm = this.fb.group({
      filterType: ['', Validators.required],
      filterProp: ['', Validators.required],
      filterOperator: ['', Validators.required],
      filterValue: ['', [Validators.required, this.alphaNumericValidator]],
      filterAndOr: [false],
    });
  }

  //builds style form
  buildStyleForm() {
    this.styleForm = this.fb.group({
      selectorType: [''],
      selectorValue: [''],
      styleType: [''],
      styleValue: [''],
    });
  }

  buildSearchForm() {
    this.searchForm = this.fb.group({
      searchType: ['', Validators.required],
      searchValue: ['', Validators.required],
    });
  }

  updateSearchLabel(value: string) {
    switch (value) {
      case 'id':
        this.currentSearchLabel = 'Search Kinase Client (example: AT5G39440)...';
        break;
      case 'description':
        this.currentSearchLabel = 'Search Kinase Client (example: CPK28)...';
        break;
      case 'family':
        this.currentSearchLabel = 'Search Kinase Client (example: CDPk Super Family)...';
        break;
      default:
        this.currentSearchLabel = 'Search Kinase Client (example: AT5G39440)...';
    }
  }

  //adds the filter in the form to the filter array
  addFilter() {
    let filterType = this.filterForm.get('filterType').value;

    let filterProp = this.filterForm.get('filterProp').value;
    let filterOperator = this.filterForm.get('filterOperator').value;
    let filterValue = this.filterForm.get('filterValue').value;

    this.filters.push({
      type: filterType,
      operator: filterOperator,
      value: filterValue,
      property: filterProp,
      filter:
        filterType +
        ': ' +
        filterProp +
        ' ' +
        filterOperator +
        ' ' +
        filterValue,
    });

    this.filter();
  }

  //adds the style in the form
  addStyle() {
    let selectorType = this.styleForm.get('selectorType').value;
    let selectorValue = this.styleForm.get('selectorValue').value;
    let styleType = this.styleForm.get('styleType').value;
    let styleValue = this.styleForm.get('styleValue').value;
    this.styles.push({
      selector: selectorType + '[' + selectorValue + ']',
      style: {
        [styleType]: styleValue,
      },
    });

    this.style();
  }

  //applies styles to graph
  style() {
    this.graph.style().fromJson(this.styles);
  }

  //set default styles
  addDefaultStlyes() {
    this.styles.push(
      {
        selector: 'node',
        style: {
          content: 'data(id)',
          'border-color': 'black',
          'border-opacity': 1,
          'border-width': '2px',
          
        },
      },
      {
        selector: 'edge',
        style: {
          'target-arrow-shape':'triangle',
          
        },
      }
      

    );
  }

   //removes filter from the filter array
  removeFilter(filt) {
    for (let x = 0; x < this.filters.length; x++) {
      if (filt == this.filters[x]) {
        this.filters.splice(x, 1);
        x = this.filters.length;
      }
    }
    this.filter();
  }

  //remove styles
  removeStyle(style) {
    for (let x = 0; x < this.styles.length; x++) {
      if (style == this.styles[x]) {
        this.styles.splice(x, 1);
        x = this.styles.length;
      }
    }
    this.style();
  }

  //Handles the graph's filtering logic
  //Essentially removes nodes and edges that do not match the filters criteria
  filter() {
    var elementsToShow = this.graphElements;
    var orElements = [];

    this.cytoData = JSON.parse(JSON.stringify(this.cytoDataFull));

    // hash of ids to keep
    let nodesToRemove = {};
    let nodesWithEdges = {};

    for (var x = 0; x < this.filters.length; x++) {
      let curFilter = this.filters[x];

      // generic node filtration
      if (curFilter.type == 'node') {
        switch (curFilter.operator) {
          case '=':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] != curFilter.value || !curNode.data[curFilter.property]) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '<':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] >= curFilter.value || !curNode.data[curFilter.property]) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '>':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] <= curFilter.value || !curNode.data[curFilter.property]) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '<=':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] > curFilter.value || !curNode.data[curFilter.property]) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;

          case '>=':
            for (var i = 0; i < this.cytoData.elements.nodes.length; i++) {
              let curNode = this.cytoData.elements.nodes[i];

              if (curNode.data[curFilter.property] < curFilter.value || !curNode.data[curFilter.property]) {
                nodesToRemove[curNode.data.id] = 1;
              }
            }
            break;
        }
      }

      // generic edge filtration
      // these get spliced right away, so our comparisons are inverse
      if (curFilter.type == 'edge') {
        switch (curFilter.operator) {
          case '=':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] != curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '<':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] >= curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '>':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] <= curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '<=':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] > curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;

          case '>=':
            for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
              let curEdge = this.cytoData.elements.edges[i];

              if (curEdge.data[curFilter.property] < curFilter.value) {
                this.cytoData.elements.edges.splice(i, 1);
              }
            }
            break;
        }
      }
    }

    // if a node is not in the filter but is neighbors with one that is,
    // we'll keep it
    // we need to add these nodes back at once to keep this from repeating unintentionally

    let nodesToAddBack = [];

    for (var i = 0; i < this.cytoData.elements.edges.length; i++) {
      let curEdge = this.cytoData.elements.edges[i].data;

      if (
        nodesToRemove[curEdge.target] != 1 ||
        nodesToRemove[curEdge.source] != 1
      ) {
        nodesToAddBack.push(curEdge.target);
        nodesToAddBack.push(curEdge.source);
      }
    }

    // add nodes to our list that have neighbors who survived the filter
    nodesToAddBack.forEach((id) => {
      nodesToRemove[id] = 0;
    });

    // now let's remove all nodes that got filtered out
    for (var i = this.cytoData.elements.nodes.length - 1; i >= 0; i--) {
      if (nodesToRemove[this.cytoData.elements.nodes[i].data.id] == 1)
        this.cytoData.elements.nodes.splice(i, 1);
    }

    for (var i = this.cytoData.elements.edges.length - 1; i >= 0; i--) {
      let curEdge = this.cytoData.elements.edges[i].data;

      if (
        nodesToRemove[curEdge.target] == 1 ||
        nodesToRemove[curEdge.source] == 1
      ) {
        this.cytoData.elements.edges.splice(i, 1);
      }
    }

    // remove nodes without edges (we need to create yet another dictionary)
    // this dict contains the ID's of all remaining target/sources in the remaining edges

    // construct dict
    let remainingEdgeDict = {};
    this.cytoData.elements.edges.forEach(edge => {
      remainingEdgeDict[edge.data.target] = 1;
      remainingEdgeDict[edge.data.source] = 1;
    });
    
    // remove nodes using dict
    for (var i = this.cytoData.elements.nodes.length - 1; i >= 0; i--) {
      let curNodeId = this.cytoData.elements.nodes[i].data.id;
      
      if (remainingEdgeDict[curNodeId] != 1)
        this.cytoData.elements.nodes.splice(i, 1);
    }

    this.populateGraph();
  }

  // additional function to handle added data from parent components
  handleParentData() {
    // for kic assay, we will handle filter data passed as a prop
    if (this.optionalData.kicExperimentId) {

      // remove existing experiment id filters
      for (var i = this.filters.length - 1; i >= 0; --i) {
        if (this.filters[i].property == "Experiments_ID") {
          this.filters.splice(i,1);
        }
      }

      if (this.optionalData.kicExperimentId == 'all')
        this.filter();

      if (this.optionalData.kicExperimentId != 'all') {

        this.filters.push({
          type: 'edge',
          operator: '=',
          value: this.optionalData.kicExperimentId,
          property: 'Experiments_ID',
          filter: 'edge: Experiments_ID = ' + this.optionalData.kicExperimentId
        });
    
        this.filter();
      }
    }
  }

  // substrate count update (really need to restructure all of this
  // because this is a kic specific thing)

  substrateRecount() {

    const maxNodeWidth = 100;
    const substrateHash = {};

    // count the number of substrates on the filtered edges
    this.cytoData.elements.edges.forEach(edge => {
      if (substrateHash[edge.data.source])
        substrateHash[edge.data.source] += 1;
      else
        substrateHash[edge.data.source] = 1
    });

    // apply the new substrate counts
    this.cytoData.elements.nodes.forEach(node => {
      if (node.data.type == 'kinase') {
        node.data.substrate_count = substrateHash[node.data.id];

        let newWidth = substrateHash[node.data.id] * 4 + 25;
        node.data.width = newWidth > maxNodeWidth ? maxNodeWidth : newWidth;
      }
    });
  }
}
