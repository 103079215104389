import { Component, OnInit } from '@angular/core';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, range } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute,Router} from '@angular/router';

@Component({
  selector: 'app-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.css']
})
export class UniversalSearchComponent implements OnInit {

  constructor(private dbaccess: DatabaseConnService, private http: HttpClient, private route: Router) { }

  select_options : Array<string> = [];
  selected_option: string;
  query: string = 'P08824';
  results: any;
  search_records: Array<any>;
  identifierControl = new FormControl(this.query);
  showWait:boolean = false;
  noData:boolean = false;
  listOfDataProteinIDs=[];

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];

  datatable = ['headers', 'data']
  columnKeysProteinIDs = {
    'P3DB ID': {"val":'p3db_id',"tooltip":"p3db ID"},
    'Protein Name': {"val":'ProteinName',"tooltip":"protein Name"},
    'Species Name': {"val":'Species Name',"tooltip":"species name"},
    'Uniprot ID': {"val":'UniprotID',"tooltip":"uniprot ID"},
    'UniParc ID': {"val":'UniParcID', "tooltip":'uni parc ID'},
    RefSeq: {"val":'RefSeq',"tooltip":"ref seq"},
    'Locus ID': {"val":'LocusID',"tooltip":"locus Id"},
    'Gene Symbol': {"val":'GeneSymbol',"tooltip":"gene symbol"},
    'Gene ID': {"val":'GeneID',"tooltip":"Gene ID"},
    EnsemblPlants: {"val":'EnsemblPlants',"tooltip":"emsembl plants"},
    EMBL: {"val":'EMBL', "tooltip":"embl"},
    STRING: {"val":'STRING',"tooltip":"string"},
    'BioGR ID': {"val":'BioGRID',"tooltip":"bio grid"},
    IntAct: {"val":'IntAct',"tooltip":"intact"},
    'Species ID': {"val":'SpeciesID',"tooltip":"specied id"}
  };

  ngOnInit(): void {
    this.dbaccess.get('idMappingKeys', []).subscribe(
      data => { 
        for (let i in data['types'])
        {
          this.select_options.push(i);
        }
        this.selected_option = this.select_options[10];
        
      }
    );
  }

  fetchJson(filename) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    
    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  user_search(): void
  {
    this.noData = false;
    //https://p3db.ddns.net:5000/api/mapping/?LocusID=AT1G01&regex=True
    this.showWait = true;
   
    this.listOfDataProteinIDs=[]
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'mapping/?'+this.selected_option+'=' + this.query + '&regex=True'
      ),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      console.log("data is in protein name : ",data);
      if(data.length == 0){
        console.log("Api is : ",environment.BASE_API_URL + 'mapping/?'+this.selected_option+'=' + this.query + '&regex=True');
        this.showWait = false;
        this.noData = true;
        
        // window.alert("No search results ....");
      }else{
        console.log("Api is : ",environment.BASE_API_URL + 'mapping/?'+this.selected_option+'=' + this.query + '&regex=True');
        // console.log("Data is  : ",data);
        this.listOfDataProteinIDs = data;
        let i=0;
        this.listOfDataProteinIDs.forEach((item) => {
          i=i+1
          item.icon = item['Phosphorylation Protein(Yes/No)'];
        });
        
        this.showWait = false;
      }
      
    
      
      console.log("list of protein Id's : ",this.listOfDataProteinIDs);
    });
  }


  handleCellClick(obj){
   
    this.route.navigate(["/p3dbid_"+obj.val]);
  
      console.log("value is : ",obj);
  }

  lazy_load(event):void{
    if (this.query === '' || event.key == "ArrowUp"
    || event.key == "ArrowDown" || event.key == "ArrowLeft" || event.key == "ArrowDown") { return; }

    this.dbaccess.getUniveral(this.selected_option, this.query, true).subscribe(
      data => { this.search_records = data['data']; }
    );
  }

  select_option(option: string): void
  {
    this.selected_option = option;
  }

}
