import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as Papa from 'papaparse';
import { MatSort } from '@angular/material/sort';
import {PtmPeptidesComponent} from '../ptm-peptides/ptm-peptides.component';
import { async, forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
@Component({
  selector: 'app-protien-blast-search',
  templateUrl: './protien-blast-search.component.html',
  styleUrls: ['./protien-blast-search.component.css'],
  animations: [
    trigger('detailExpand', [
      state('void', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
      state('*', style({height: '*', visibility: 'visible'})),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers:[PtmPeptidesComponent]
})
export class ProtienBlastSearchComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  //@ViewChild(PtmPeptidesComponent) ptmPeptidesComponent: PtmPeptidesComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator1: MatPaginator;
  blastDataSource = new MatTableDataSource<any>([]);
  public method: string;
  public proteinSeq: string;
  public database: string;
  public matrix: string;
  public evalue: string;
  public items: any;
  public type: string;
  databaseData:any;
  isLoading: boolean;
  result: boolean;
  blastRes = [];
  loading = false;
  blastError = false;
  alertVisible = false;
  blastForm: FormGroup;
  showMatcard = false;
  displayedColumns: string[] = [ 'query_sequence', 'subject_sequence', 'pident', 'gapopen', 'evalue','bitscore'];
  listOfDataPeptides=[];
  dataSource = new MatTableDataSource<any>(this.listOfDataPeptides);
  columnKeysPeptides: any;
  searchIndex: any;
  clickedSequence=false;
  phosphorylation_sites = [];
  proteinId = [];
  
  sequenceDisplayedColumn: string[] = ['expand','detected_peptide','peptide_feature','peptide','peptide_in_protein','site_in_peptide','internal_protein_id','fastaHeader']
  loadingSequence=false;
  databaseOptions: any;
  expandedData=[];
  expandedElement: any = null;

  constructor(private http: HttpClient, private router: Router, private ptm: PtmPeptidesComponent) {
    
  }

  async ngOnInit(): Promise<void> {
    this.result=false;
    const response= await fetch('https://p3db.ddns.net:5004/api/blast_db_list/');
    this.databaseData = await response.json();
    console.log(this.databaseData.data);
    this.type='peptide';
    this.database = '';
    this.evalue = "1";
    this.matrix = "BLOSUM62";

    this.onTypeChange(this.type);
  }
  
  ngAfterViewInit() {
    // Now ptmComponent will be available
  }
  fetchJson(filename) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    
    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  onTypeChange(type){
   
    this.databaseOptions= this.databaseData.data[type]
    
  }
  

  onSubmit() {
    this.result=false;
    this.listOfDataPeptides=[];
    this.dataSource = new MatTableDataSource<any>(this.listOfDataPeptides);
    this.blastDataSource= new MatTableDataSource([]);
    if (this.proteinSeq === undefined){
      this.showAlert();
      return;
    }
    if (this.proteinSeq.length<1){
      this.showAlert();
      return;
    }
    this.showMatcard=true;
    this.loading = true;
    this.blastError = false;
    this.proteinSeq = this.proteinSeq.replace(/\n/g, '');
    console.log('sequence is',this.proteinSeq);
    this.load_Sequence_Data(this.proteinSeq,this.database,this.type).subscribe(
      data=>{
        console.log('API response is:',data)
        this.blastDataSource= new MatTableDataSource(data['data']);
        console.log(this.blastDataSource)
        this.result=true;
      },
      error=>{
        console.log('API Error ',error)
      }
    );
    //this.loadCsv();
    this.loading=false;
  }
  load_Sequence_Data(proteinSequence,blastDb,seqType): Observable<any>{
    const params = new HttpParams()
      .set('protein_sequence', proteinSequence)
      .set('blast_db', blastDb)
      .set('seq_type', seqType);
      return this.http.get('https://p3db.ddns.net:5004/api/blastapi/',{params});
  }
  loadCsv() {
    this.http.get('assets/final_results_no_duplicates.csv', { responseType: 'text' })
      .subscribe(data => {
        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const formattedData = result.data.map((row: any) => ({
              
              query_sequence: row['query_sequence'] ,
              subject_sequence: row['subject_sequence'],
              pident: row['pident'],
              gapopen: row['gapopen'],
              evalue: row['evalue'],  // Assuming expand details can be generated or are in the CSV
              bitscore: row['bitscore']
            }));
            this.blastDataSource=new MatTableDataSource(formattedData)
            this.result=true;
          
          }
          
        });
        console.log(this.blastDataSource);
      });
  }

  onSequenceClick(sequence){
    this.loadingSequence=true;
    this.listOfDataPeptides=[];
    //sequence='STIGVEFATR'
    this.getDataBySequence(sequence);
    

  }
  async onP3DB_ID_Click(id){
    const url = `https://www.p3db.org/p3dbid_${id}`;
    window.open(url, '_blank');

   
  }
  getDataBySequence(val) {
    // this.showWait = true;
    forkJoin([
      this.fetchJson(
         'https://p3db.ddns.net:5004/api/get_peptide_details/?peptide=' + val
      ),
    ]).subscribe((result) => {
      console.log("API is : "+ 'https://p3db.ddns.net:5000/api/get_peptide_details/?peptide=' + val)
      console.log("api data is : ",result);
      let data = result[0]['data'];
      this.expandedData=data;
     
      console.log(this.expandedData)
      this.dealInfo(
        data.peptides,
        data.peptide_features,
        data.paper_info,
        data.protein_detail
      );
      // for (let item in data.protein_detail) {
      //   let sites = [];
      //   data.protein_detail[item][0].ptm_sites.forEach((element) => {
      //     sites.push(element.site_in_protein);
      //   });
      //   this.dealPtmSites(
      //     sites,
      //     data.protein_detail[item][0].sequence,
      //     data.protein_detail[item][0].internal_protein_id
      //   );
      // }
    });
  }
  dealInfo(peptides, features, paperInfo, protein_detail){
    this.listOfDataPeptides=[];
    for (let item in peptides) {
      let internalProteinId = peptides[item].internal_protein_id;
      let feature = '';
      if(features[item]==null){
        feature = "N/A "+'\n';
      }else{
        features[item].forEach((item) => {
          feature += item.feature_name + ' : ' + item.feature_value + '\n';
        });
      }
      console.log("ads : ",protein_detail )
      let hasArray = false;
      for (const key in protein_detail) {
        if (Array.isArray(protein_detail[key])) {
          hasArray = true;
        }
        break;
      }

      if(Object.keys(protein_detail).length === 0){
        peptides[item].fastaHeader = "N/A";
      peptides[item].highlightProteinName = "false";
      peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '<br>';
      peptides[item].description +=
        'Fasta Header: ' + "N/A " + '<br>';
      peptides[item].description +=
        'External Id Type: ' + "N/A " + '<br>';
      peptides[item].description += 'ID: ' + " N/A" + '<br>';
      peptides[item].description += 'Length: ' + " N/A" + '<br>';
      peptides[item].description +=
        'Organism Id: ' + " N/A" + '<br>' + '<br>';
      peptides[item].description += "Paper's Information:" + '<br>';
      if (paperInfo[peptides[item].datasource]) {
        peptides[item].description +=
            'Title:' + (paperInfo[peptides[item].datasource].title || "N/A") + '<br>';
        peptides[item].description +=
            'Link:' + (paperInfo[peptides[item].datasource].link || "N/A") + '<br>';
        peptides[item].description +=
            'Year:' + (paperInfo[peptides[item].datasource].year || "N/A") + '<br>';
    } else {
        peptides[item].description += 'Title: N/A<br>';
        peptides[item].description += 'Link: N/A<br>';
        peptides[item].description += 'Year: N/A<br>';
    }
      peptides[item].id = peptides[item].internal_peptide_id;
      this.listOfDataPeptides.push(peptides[item]);
      }else{
        
        if(hasArray){
          
          let fasta_header = protein_detail[peptides[item].internal_protein_id][0].fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          }      
        peptides[item].fastaHeader = fasta_header;
        peptides[item].highlightProteinName = "false";
        peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '<br>';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail[internalProteinId][0].fasta_header + '<br>';
        peptides[item].description +=
          'External Id Type: ' + protein_detail[internalProteinId][0].external_id_type + '<br>';
        peptides[item].description += 'ID: ' + protein_detail[internalProteinId][0].id + '<br>';
        peptides[item].description += 'Length: ' + protein_detail[internalProteinId][0].length + '<br>';
        peptides[item].description +=
          'Organism Id: ' + protein_detail[internalProteinId][0].organism_id + '<br>' + '<br>';
        peptides[item].description += "Paper's Information:" + '<br>';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '<br>';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '<br>';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '<br>';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
        else{
          let fasta_header = protein_detail.fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          } 
        peptides[item].fastaHeader = fasta_header;
        
          peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '<br>';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail.fasta_header + '<br>';
        peptides[item].description +=
          'External Id Type: ' + protein_detail.external_id_type + '<br>';
        peptides[item].description += 'ID: ' + protein_detail.id + '<br>';
        peptides[item].description += 'Length: ' + protein_detail.length + '<br>';
        peptides[item].description +=
          'Organism Id: ' + protein_detail.organism_id + '<br>' + '<br>';
        peptides[item].description += "Paper's Information:" + '<br>';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '<br>';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '<br>';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '<br>';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
      }

      
      
      console.log("List of Data Peptides : ",this.listOfDataPeptides);
      this.dataSource = new MatTableDataSource<any>(this.listOfDataPeptides);
      this.loadingSequence=false;
      
    }
  }
  
  

  setDefaultSearch() {
    this.proteinSeq = "GESGDESEEDDEDGL";
  }


  showAlert() : void {
    alert("Sequence should not be empty");
    console.log("alert")
  }
  locusClicked(id){
    console.log(typeof(id))
    window.open('https://www.arabidopsis.org/locus?name='+id.split('.')[0],'_blank')

  }

  fn_seqHighlight(item) {
    let style = {
      seq_highlight01: true,
      seq_highlight02: false,
    };
    if (item.class == 'highlight') {
      style = {
        seq_highlight01: true,
        seq_highlight02: true,
      };
    }
    return style;
  }

  clear(){
    this.showMatcard=false;
    this.blastDataSource=new MatTableDataSource();
    this.listOfDataPeptides=[];
    this.dataSource=new MatTableDataSource<any>(this.listOfDataPeptides); 
    this.proteinSeq='';
    this.type='peToPE';
    this.database = 'one';
    this.evalue = "1";
    this.matrix = "BLOSUM62";
    this.type='peToPE';
    this.result=false;
    this.phosphorylation_sites=[];
  }
}


