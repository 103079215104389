import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() tableData;
  displayedColumns;
  dataSource;
  baseUrl: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  hoverTop: number = 0;
  hoverLeft: number = 0;

  constructor(
    private clipboard: Clipboard,
    private location: Location
  ) {
    // Get the base URL of the application
    this.baseUrl = window.location.origin;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  getFullUrl(routerLink: string): string {
    return `${this.baseUrl}${routerLink}`;
  }

  ngOnInit(): void {
    this.displayedColumns = this.tableData['name'];
    this.dataSource = new MatTableDataSource(this.tableData['data']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
      // console.log(this.tableData)
      // console.log(this.dataSource)

  }

  ngOnChanges() {
    // console.log(this.tableData)
    this.displayedColumns = this.tableData['name'];
    this.dataSource = new MatTableDataSource(this.tableData['data']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.tableData['data']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  condenseText(text: String) {
    if (text.length <= 10) {
      return text; 
    } else {
      return text.substring(0, 25) + "...";
    }  }

    // shortens the cell and then gives an option for copying the text
  groupsToBeCondensed1(text: String) {
    if (text == "gen_seq" || text == "protein_seq")
      {
        return true;
      }
    else {
      return false;
    }
  }

   // shortens the cell and then gives an option to hover to show full text
  groupsToBeCondensed2(text: String) {
    if (text == "Title")
      {
        return true;
      }
    else {
      return false;
    }
  }


  groupsToBeHyperlinked(text: String) {
    // For PubmedID, we'll handle it in the template directly
    if (text === "PubmedID") {
      return false;
    }
    return text === "experimental_study" || text === "p3db_id";
  }

  groupsToBeRouterLinked(text: String, element?: any) {
    if (text === "internal_protein_id_count" || text === "PubmedID") {
      if (element === 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  copyText(text: string) {
    this.clipboard.copy(text);
  }

  updateHoverPosition(event: MouseEvent) {
    // Add offset to position the hover box below the cursor
    this.hoverTop = event.pageY + 20;
    this.hoverLeft = event.pageX - 150; // Center the box horizontally relative to cursor
  }

}
