//by ziting
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as Papa from 'papaparse';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ortholog-conservation',
  templateUrl: './ortholog-conservation.component.html',
  styleUrls: ['./ortholog-conservation.component.css']
})
export class OrthologConservationComponent implements OnInit {

  constructor(private http: HttpClient) { }
  ELEMENT_DATA;
  csvData: any;

  ngOnInit(): void {
    this.loadCsv();
  }

  getData(){
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'conservation?'),
    ]).subscribe(result => {
        console.log("fetch",result[0]);
        this.dealInfo(result[0]['data']);
      });
  }

  fetchJson(filename){
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  getCsvData(filePath: string): Observable<any> {
    return this.http.get(filePath, { responseType: 'text' }).pipe(
      map(csvData => {
        let parsedData: any;
        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            parsedData = result.data;
          }
        });
        return parsedData;
      })
    );
  }

  loadCsv(): void {
    this.getCsvData('assets/Supplemental Table 2_Chunhui_v2.csv').subscribe(data => {
      this.csvData = data;
      console.log(this.csvData);
      this.dealInfo(this.csvData);
    });
  }

  dealInfo(data){
    console.log(data);
    this.ELEMENT_DATA = {
      name: ["Unique identifier","Sequence","Pep count","Source","UniProt IDs","Peptide_Phosites","Matched IDs","GO_IDs","GO_Terms","Uniprot Orthogs in Soybean/Rice/Medicago/Zeamays/Lotus","Conserved phosphorylation count","new location on MSA results","Conserved Phosites","conservation value", "Rice_RAP_ID", "Rice_MSU_ID","Soybean_Locus_ID"],
      display: ["Unique identifier","Sequence","Pep count","Source","UniProt IDs","Peptide_Phosites","Matched IDs","GO_IDs","GO_Terms","Uniprot Orthogs in Soybean/Rice/Medicago/Zeamays/Lotus","Conserved phosphorylation count","new location on MSA results","Conserved Phosites","conservation value", "Rice_RAP_ID", "Rice_MSU_ID","Soybean_Locus_ID"],
      data:data,
    };
  }

  // dealInfo(data){
  //   console.log(data);
  //   this.ELEMENT_DATA = {
  //     name: ["uniprot_id","conservation_value","conserved_pho_sites","locus_id","orthogs","pep_len","peptide","pho_site_on_full_sequence","pho_site_on_peptide","pho_sites_on_MSA"],
  //     display: ["uniprot_id","conservation_value","conserved_pho_sites","locus_id","orthogs","pep_len","peptide","pho_site_on_full_sequence","pho_site_on_peptide","pho_sites_on_MSA"],
  //     data:data,
  //   };
  // }

}