import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModalComponent } from '../modal/modal.component';
import { INFERRED_TYPE } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'p3dbTable',
  templateUrl: './p3db-table.component.html',
  styleUrls: ['./p3db-table.component.css'],
})
export class P3dbTableComponent implements OnInit {
  constructor(private dialogRef: MatDialog) {}
  @Output() handleCellClick = new EventEmitter();
  @Output() getProteinIds = new EventEmitter<any>();
  @Input() presentInDatabase: boolean = false;
  @Input() columnKeys: Object; // information of the columns : format: {columnName:key}
  @Input() dataSourceShow: Array<any> = []; //table data: format: {key:val}
  @Input() searchIndex: number; //the index of the column that can be searched
  @Input() expandable:boolean = false;
  @Input() hasCheckbox:boolean = false;
  @Input() hasIcon:boolean = false;
  @Input() iconHeader:String = ''; // column title for icon column
  @Input() iconTip:String = '';
  public pageSize: number = 5;
  public total: number = 0;
  public pageIndex: number = 1;
  public searchValue = '';
  public isShowSearchBar = false;
  public dataSource: any;
  showModal: boolean = false;
  checkedP3db_ids  =[];
  masterCheckboxChecked:boolean;
  public nzPageSizeOptions:Array<number>=[5,10,15,20]
  isDialogVisible = false;
  expandSet = new Set<number>();
  objectKeys = Object.keys;
  ngOnInit(): void {
    this.dataSource = this.dataSourceShow;
    this.setPagination()
    this.handlePagination(this.pageIndex, this.pageSize)
    this.updateSelectionStateForAll();
  }
  setPagination(){
    this.total = this.dataSourceShow.length;
    this.pageSize = 5
    this.pageIndex = 1
  }
  getRowClassName = (index) => {
    return index % 2 == 0 ? '' : 'evenRow';
  };
  nzPageSizeChange(newSize) {
    this.handlePagination(this.pageIndex, newSize)
  }
  nzPageIndexChange(newIndex) {
    this.handlePagination(newIndex, this.pageSize)
  }
  handlePagination(currentIndex, perPageNumber){
    this.dataSourceShow = this.dataSource.slice((currentIndex-1)* perPageNumber, currentIndex*perPageNumber);
    this.updateSelectionStateForAll();
  }
  cellClick(col, val, rowData) {
    console.log("row data : ",rowData);
    if(rowData.hasOwnProperty('LocusID')){
      if(rowData.LocusID==""){
        this.handleCellClick.emit({col:col, val:val, rowData: rowData});
      }else{
        this.handleCellClick.emit({col:col, val:val, rowData: rowData, locusID: rowData.LocusID[0].split(".")[0]  })
      }
    }else{
      this.handleCellClick.emit({col:col, val:val, rowData: rowData, locusID: rowData.fastaHeader.split(".")[0]  })
    }
    
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  formatValue(data){ //This funtion is to get site_in_peptide count correctly i.e to get [794,804] instead of [1,11]

    let site=data.site_in_peptide
    if(site=="[]"){
      const result = "[" + data.peptide_in_protein + "]";
      return result;
    }else{
      let siteWithoutBrackets = site.slice(1,-1);
    let siteArray = siteWithoutBrackets.split(",");
    // Map the original array to add 'data.peptide_in_protein' to each element
    const modifiedArray = siteArray.map(item => (parseInt(item) + parseInt(data.peptide_in_protein) - 1).toString());
    // Create a result string with modified elements enclosed in square brackets
    const result = "[" + modifiedArray.join(",") + "]";
    return result;
    }
    
  }

  checkCondition(api_data,index,key){ //This function is to exactly the site_in_peptide column of the Phosphorylation Peptides in Protein table
     return api_data.hasOwnProperty(key)&& index==4;
  }

  search(): void {
    this.isShowSearchBar = false;
    this.dataSourceShow = this.dataSource.filter((item) => {
      return (
        item[this.columnKeys[this.objectKeys(this.columnKeys)[0]]].indexOf(
          this.searchValue
        ) !== -1
      );
    });
    this.setPagination()
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  openDialog(proteinName) {
      
    this.dialogRef.open(ModalComponent, {
      data: {
        protein : proteinName
      }});
  }

  navigateToLink(link: string): void {
    const modifiedLink = 'https://www.arabidopsis.org/servlets/TairObject?name=' + link.split(".")[0] + '&type=locus';
    window.open(modifiedLink, '_blank');
  }
  
  onCheckboxChange(event: any, data: any): void {
      data.isSelected = event.checked;  // Update individual item selection

      // Check if all items are selected
       // Assuming you have a masterCheckboxChecked variable
    
       data.isSelected = event.checked; // Update individual item selection
       if (event.checked) {
         this.checkedP3db_ids.push(data.p3db_id);
       } else {
         this.checkedP3db_ids = this.checkedP3db_ids.filter(id => id !== data.p3db_id);
       }
       // Update master checkbox state based on all items on current page
       this.masterCheckboxChecked = this.dataSourceShow.every(item => item.isSelected);
       this.getProteinIds.emit(this.checkedP3db_ids);
  }

  selectAllChange(event: any): void{
    
    const isAllSelected = event.checked;
  if (isAllSelected) {
    // Add all IDs to the selected list
    this.dataSource.forEach(item => {
      if (!this.checkedP3db_ids.includes(item.p3db_id)) {
        this.checkedP3db_ids.push(item.p3db_id);
      }
    });
  } else {
    // Clear all IDs from the selected list
    this.checkedP3db_ids = [];
  }
  // Update selection state based on full dataset, not just visible data
  this.updateSelectionStateForAll();
    this.getProteinIds.emit(this.checkedP3db_ids);
  }

  updateSelectionStateForAll(): void {
    // This function updates the isSelected property for all items that are currently loaded
    // This should be called after updating checkedP3db_ids to ensure UI consistency
    this.dataSourceShow.forEach(item => {
      item.isSelected = this.checkedP3db_ids.includes(item.p3db_id);
    });
    // Recheck if all items on the current page are selected to update the master checkbox
    this.masterCheckboxChecked = this.dataSourceShow.every(item => item.isSelected);
  }

}