<mat-card id="upload-container" style="margin-top: 10px;">
  <mat-card>
    <h1 id="title" style="text-align: center;"><b>AI Gene - Detection</b></h1>
  </mat-card> 
  <mat-card style="margin-top: 10px;">
    <div class="Choosefile-container">
      <label for="formFile" class="file-label">Upload Image to GPT</label>
      <input (change)="onFilechange($event)" class="file-control" type="file" id="formFile">
      <button (click)="upload()" type="button" class="upload-button">Analyze</button>
      <button (click)="reset()" type="button" class="upload-button">Reset</button>
    </div>
  </mat-card>


  <!-- displaying Table-->
  <mat-card style="margin-top: 10px;" *ngIf="isFileUploaded">

    <div *ngIf="!ELEMENT_DATA" class = "loading_text">
      <p> Processing image...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <mat-card-header *ngIf="loadingImage == false">
      <mat-card-title class="file-label">Uploaded Pathway</mat-card-title>
    </mat-card-header>
    <div *ngIf="isFileUploaded && loadingImage == false">
      <img *ngIf="imageSrc" [src]="imageSrc" alt="Uploaded Image" style="max-width: 100%; height: auto;" />
      <p *ngIf="imageError">{{ imageError }}</p>
    </div>

   <mat-card-header *ngIf="loadingImage == false">
      <mat-card-title class="file-label">Results</mat-card-title>
    </mat-card-header>
  
    <div *ngIf="loadingImage == false">
      <app-table [tableData]="ELEMENT_DATA"></app-table>
    </div>
    <!-- <div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef> {{ column }}</th>
          <td mat-cell *matCellDef="let element"> {{ element[column] }}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div> -->
  
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->

  </mat-card>
  
</mat-card>