import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Phosphosite, ProteinData, ProteinInteraction, Ontology, DomainInfromation } from 'src/app/interfaces/protein-data';
import { RouterModule } from '@angular/router';
import { DatabaseConnService } from 'src/app/Services/database-conn.service';

@Component({
  selector: 'app-protein-data',
  templateUrl: './protein-data.component.html',
  styleUrls: ['./protein-data.component.css'],
  encapsulation: ViewEncapsulation.None,
})

//=============================================================================
// ProteinData Component
//=============================================================================
// Author(s): Michael Fisher (June 2021)
//=============================================================================
// This component is designed to provide functionality for the 
// https://dev.p3db.org/protein-data/:id page. The following sections are divided
// up based on role and tasks for the functionality of the page, and have their
// respective fields and methods assigned as such
//=============================================================================
// == Database access:
// Fields: 
// public proteinData?: ProteinData
//
// methods:
// private pullProteinData(): void
//
// == JSON Processing Functions:
// Fields:
//
// Methods:
// private unpackProteinData(any) : ProteinData
// private unpackPhosphosites(string) : Phosphosite[]
// private unpackProteinInteractions(stirng) : ProteinInteraction[]
// private unpackOntology(string): Ontology[]
// private unpackDomainInformation(string) : DomainInformation[]
//
// == Table Formatting:
// Fields:
// public phosphositeColumns: string[]
// public sequenceColumns: string[]
// public interactionColumns: string[]
// public ontologyColumns: stirng[]
// public domainColumns: string[]
//
// Methods:
// private generateAccessionColumns() : void
//
// == Sequence Formatting and highlighing :
// Fields: 
// public sequenceData: any{}
// public surroundingSeq: string | null;
// public activeElement: number |null;
// private demark: Number
// private offset: Number
//
// Methods:
// public generateSurroundingSequence(Phosphosite) : void
// private generateSequenceData() : void
// private is_element(any[], any) : boolean
// private highlightSequence() : void
//
// == Misc Functions:
// Fields:
// public proteinMessage?: string
// public displayField: number | null
// private proteinID?: string
// 
// Methods:
// public toggle(number) : void
// private sanitize(string) : string
//=============================================================================

export class ProteinDataComponent implements OnInit {

  constructor(private database: DatabaseConnService,
              private route: ActivatedRoute,
              private router: Router) 
              { }

  ngOnInit(): void {
    this.proteinID = this.route.snapshot.params.id;
    this.pullProteinData();
  }
  //===========================================================================
  // Database access
  //===========================================================================
  public proteinData?: ProteinData;

  private pullProteinData(): void {
    this.database.get('protein_data', [this.proteinID, ""])
    .subscribe(
      proteinData => {  
                        // console.log(proteinData);
                        this.proteinData = this.unpackPorteinData(proteinData['data']); 
                        this.generateAccessionColumns();
                        this.generateSequenceData();
                        this.highlightSequence();
                        this.proteinMessage = proteinData['message'];
                         },

      errorData => { this.proteinMessage = errorData.message; }
    )
  }

  // ==========================================================================
  // JSON processing functions
  //===========================================================================


  private unpackPorteinData(proteinData: any): ProteinData {
    let protein: ProteinData = {
      description: this.sanitize(proteinData['description']),
      organism : this.sanitize(proteinData['organism']),
      noSites : this.sanitize(proteinData['no_of_sites']),
      noSpectra : this.sanitize(proteinData['no_of_spectra']),
      preferenceLink : this.sanitize(proteinData['preference_link']),
      sequence : this.sanitize(proteinData['sequence']),
      phosphosites : this.unpackPhosphosites(proteinData['phosphosites']),
      proteinIDs: proteinData['protein_ids'] ? proteinData['protein_ids'] : 'N/A',
      proteinInteractions : this.unpackProteinInteractions(proteinData['protein_interaction']),
      ontologies : this.unpackOntology(proteinData['ontology']),  
      kinaseSubstrate : proteinData['kinase_substrate'] ? proteinData['kinase_substrate'] : 'N/A',
      domainInformation : this.unpackDomainInformation(proteinData['domain_information']),
    }

    return protein;
  }

  private unpackPhosphosites(phosphosites: string): Phosphosite[] {
    let phospohList = [];

    for (let i = 0; i < phosphosites['position'].length; ++i)
    {
      console.log(phosphosites)
      let phos : Phosphosite = {
        position: this.sanitize(phosphosites['position'][i]),
        aminoAcid: this.sanitize(phosphosites['amino_acid'][i]),
        id: this.sanitize(phosphosites['id'][i]),
        ref: this.sanitize(phosphosites['ref'][i])
      }

      phospohList.push(phos);
    }
    
    return phospohList;
  }

  private unpackProteinInteractions(proteinData: string): ProteinInteraction[] {
    let interactions = [];

    for (let i = 0; i < proteinData.length; ++i)
    {
      let proteinInteraction: ProteinInteraction = {

        interactorA : this.sanitize(proteinData[i]['interactor_a']),
        interactorB : this.sanitize(proteinData[i]['interactor_b']),
        officialSymbolForA : this.sanitize(proteinData[i]['official_symbol_for_a']),
        officialSymbolForB : this.sanitize(proteinData[i]['official_symbol_for_b']),
        aliasesForA : this.sanitize(proteinData[i]['aliases_for_a']),
        aliasesForB : this.sanitize(proteinData[i]['aliases_for_b']),
        experimentalSystem : this.sanitize(proteinData[i]['experimental_system']),
        source : this.sanitize(proteinData[i]['source']),
        pubmedID : this.sanitize(proteinData[i]['pubmed_ID']),
        orangismAID : this.sanitize(proteinData[i]['organism_A_ID']),
        organismBID : this.sanitize(proteinData[i]['organism_B_ID']),
        p3dbID : this.sanitize(proteinData[i]['p3dbId']),
        otherID : this.sanitize(proteinData[i]['otherId']),
        otherType : this.sanitize(proteinData[i]['otherType']) 
      }

      interactions.push(proteinInteraction);
    }
    
    return interactions;
  }

  private unpackOntology(ontologyData: string): Ontology[] {
    let ontologies = [];

    for (let i = 0; i < ontologyData.length; ++i)
    {
      let ontology: Ontology = {
        ontologyID : this.sanitize(ontologyData[i]['ontologyID']),
        name : this.sanitize(ontologyData[i]['name']),
        def : this.sanitize(ontologyData[i]['def']),
        synonym : this.sanitize(ontologyData[i]['synonym']),
        xref : this.sanitize(ontologyData[i]['xref'])
      }

      ontologies.push(ontology);
    }

    return ontologies;
  }

  private unpackDomainInformation(domainData: string): DomainInfromation[] {
    let domains = [];

    for (let i = 0; i < domainData.length; ++i)
    {
      let domain : DomainInfromation = {
        
        proteinID : this.sanitize(domainData[i]['proteinID']),
        applicationName : this.sanitize(domainData[i]['applicationName']),
        domainID : this.sanitize(domainData[i]['domainID']),
        domainDescription : this.sanitize(domainData[i]['domainDescription']),
        matchStart : this.sanitize(domainData[i]['matchStart']),
        matchEnd : this.sanitize(domainData[i]['matchEnd']),
        p3dbID : this.sanitize(domainData[i]['p3dbId']),
        otherID : this.sanitize(domainData[i]['otherId']),
        otherType : this.sanitize(domainData[i]['otherType']) 
      }

      domains.push(domain);
    }

    return domains;
  } 

  //===========================================================================
  // Table formatting
  //===========================================================================

  public phosphositeColumns = ['position', 'aminoAcid', 'phosphosite'];
  public sequenceColumns = ['sequence', 'sequenceData'];
  public interactionColumns = ['interactorA', 'interactorB', 'experimentalSystem', 'source', 'pubmedID'];
  public ontologyColumns = ['ID', 'name', 'synonym'];
  public domainColumns = ['ID', 'name', 'description', 'start', 'end'];

  public proteinColumn1 = [];
  public proteinColumn2 = [];

  private generateAccessionColumns(): void {
    for (let i = 0; i < this.proteinData.proteinIDs.length; ++i)
    {
      if (i % 2 == 0) this.proteinColumn1.push(this.proteinData.proteinIDs[i]);
      else this.proteinColumn2.push(this.proteinData.proteinIDs[i]);
    }
  }

  //===========================================================================
  // Sequence Formatting and highlighing 
  //===========================================================================

  public sequenceData = {};
  public surroundingSeq: string | null;
  public activeElement: number |null;
  private demark = 50;
  private offset = 1;

  public generateSurroundingSequence(data: Phosphosite) : void {
    let ret = '';
    let pos = Number(data.position);
    let sequence = this.proteinData.sequence;

    if (pos < 5)
    {
      ret = sequence.slice(0, pos) + sequence[pos] + sequence.slice(pos + 1, 11);
    }
    else if (pos > sequence.length - 5)
    {
      ret = sequence.slice(sequence.length - 11, pos) + sequence[pos] + sequence.slice(pos + 1, sequence.length);
      pos = (pos - sequence.length + 11);
    }
    else
    {
      ret = sequence.slice(pos - 6, pos) + sequence[pos] + sequence.slice(pos + 1, pos + 5);
      pos = 6;
    }
    this.surroundingSeq = ret;
    this.activeElement = pos - 1;

    // this.navigatePhosphorylation(data.id);
  }

  private generateSequenceData() : void {
    let data = this.proteinData.sequence;
    let len = 0;
    for (let i = 0; i < data.length;)
    {
      let x ={}
      x[len + this.offset] = data.slice(i, i + this.demark);
      this.sequenceData[i + this.offset] = data.slice(i, i + this.demark);
      i += this.demark;
    }
  }

  private is_element(container: any[], item: any): boolean {
    for (let i = 0; i < container.length; ++i)
    {
      if (item == container[i]) return true;
    }
    return false;
  }

  private highlightSequence(): void 
  {
    let locDic = {};

    let phosphosite = this.proteinData.phosphosites;

    // finding the position of the characters to be replaced
    for (let i = 0; i < phosphosite.length; ++i)
    {
      let abs = Number(phosphosite[i].position);
      let rel = Number(phosphosite[i].position) % this.demark;

      let loc =  abs - rel + this.offset;

      if (locDic[loc]) locDic[loc].push(rel - this.offset);
      else locDic[loc] = [rel - this.offset];
    }

    // actually replacing the characters 
    for (let key in locDic)
    {
      let seq =  this.sequenceData[key];
      let str = '';
      for (let i = 0; i < seq.length; ++i)
      {
        if (this.is_element(locDic[key], i))
        {
          str += '<span class="highlightText">' + seq[i] + '</span>'

        }
        else
        {
          str += seq[i];
        }
      }

      this.sequenceData[Number(key)] = str;
    }

    // tidying up, dont delete
    let seqlist = []
    let i = this.offset;
    for (let key in this.sequenceData)
    {
      seqlist.push({len: i, data: this.sequenceData[key]})
      i += this.demark;
    }
    this.sequenceData = seqlist;
  }

  //============================================================================
  // Misc functions
  //============================================================================

  public proteinMessage?: string;
  public displayField: number | null;
  private proteinID?: string;
  

  private sanitize(obj: string): string { return obj ? String(obj).replace('\/', '/') : 'N/A' }
  public toggle(option: number): void 
  { 
    if (option == this.displayField) this.displayField = null;
    else this.displayField = option;
  }

  public navigatePhosphorylation(id: string): void
  {
    console.log(id, typeof id);
    this.router.navigate(['/phosphorylation-data/', id]);
  }

}
