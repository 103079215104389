import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ask-ai',
  templateUrl: './ask-ai.component.html',
  styleUrls: ['./ask-ai.component.css']
})
export class AskAiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
