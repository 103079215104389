<mat-card class="container-card">
  <mat-card class="background-card">
    <div style="padding-bottom: 10px;">
      <mat-card class="heading">
        <h1 id="title">Kinases</h1>
      </mat-card>
    </div>

    <!-- Dropdown Menu for Selecting Species -->
    <div style="padding-bottom: 10px;">
      <mat-card class="heading">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Search Species</mat-label>
          <input
            type="text"
            matInput
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            [value]="displaySpecies(selectedSpecies)"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSpeciesSelect($event.option.value)">
            <mat-option *ngFor="let species of filteredSpecies | async" [value]="species">
              {{ displaySpecies(species) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mat-card>
    </div>

    <div class="table-container">
      <app-table [tableData]="ELEMENT_DATA"></app-table>
    </div>
  </mat-card>
</mat-card>
