<mat-card class="container">
  <mat-card class="title">
    <p>Blast Tool</p>
  </mat-card>
  <mat-card class="textarea">
    
    
    <div class="input-field col s6">
      <p style="font-weight: bold; margin-bottom: 0; font-size: large;">Sequence</p>
      <textarea style="height: 100px; width: 100%;" name="fasta" class="browser-default input-area"
        [(ngModel)]="proteinSeq"></textarea>
    </div>

    <div class="type" style="margin-top: 2%;">
      <p style="margin-bottom: 0; font-weight: bold;">Type
        <mat-icon style="font-size: small;"
          matTooltip="Mention Type"
          matTooltipPosition="after"> help
        </mat-icon>
      </p>
    <mat-radio-group  color="primary"  [(ngModel)]="type" (ngModelChange)="onTypeChange($event)">
      <mat-radio-button value="peptide">Peptide To Peptide</mat-radio-button>
      <mat-radio-button value="protein">Peptide To Protein</mat-radio-button>
      <mat-radio-button value="protein">Protein To Protein</mat-radio-button>
    </mat-radio-group>
    </div>

    <div class='database_area' >
      <p >Database</p>
      <mat-form-field>
        <mat-label>Select Database</mat-label>
        <mat-select [(ngModel)]="database">
          <mat-option  *ngFor="let db of databaseOptions" [value]="db">{{ db }}</mat-option>
          
        </mat-select>
      </mat-form-field>
    </div>

    <div class ="threshold">
      <p style="font-weight: bold; margin-bottom: 0;">E-value threshold
        <mat-icon style="font-size: small;" matTooltip="E-value, or Expectation value, is a metric that estimates the number of random alignments expected to achieve a particular alignment score by chance when searching a database of a given size.
              
              We recommend using 0.01 or 0.1 as the initial values for the search" matTooltipPosition="after"> help
        </mat-icon>
      </p>
      <div id="threshold">
        <mat-radio-group color="primary" [(ngModel)]="evalue" >
          <mat-radio-button class="option" value="0.0001">0.0001</mat-radio-button>
          <mat-radio-button class="option" value="0.001">0.001</mat-radio-button>
          <mat-radio-button class="option" value="0.01">0.01</mat-radio-button>
          <mat-radio-button class="option" value="0.1">0.1</mat-radio-button>
          <mat-radio-button class="option" value="1">1</mat-radio-button>
          <mat-radio-button class="option" value="10">10</mat-radio-button>
          <mat-radio-button class="option" value="100">100</mat-radio-button>
          <mat-radio-button class="option" value="1000">10000</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="matrix">
      <div class="col s6" style="margin-top: 2%;">
        <p style="margin-bottom: 0; font-weight: bold;">Scoring Matrix
          <mat-icon style="font-size: small;"
            matTooltip="Scoring matrix is a table used to determine the score of aligning two amino acids or nucleotides during a sequence alignment.
                
                Two matrix, PAM (Point Accepted Mutation) and BLOSUM (Blocks Substitution Matrix) are using for protein sequences, and nucleotide scoring matrices for DNA sequences."
            matTooltipPosition="after"> help
          </mat-icon>
        </p>
        <mat-radio-group color="primary" [(ngModel)]="matrix">
          <mat-radio-button class="option" value="PAM30">PAM30</mat-radio-button>
          <mat-radio-button class="option" value="PAM70">PAM70</mat-radio-button>
          <mat-radio-button class="option" value="PAM250">PAM250</mat-radio-button>
          <mat-radio-button class="option" value="BLOSUM80">BLOSUM80</mat-radio-button>
          <mat-radio-button class="option" value="BLOSUM62">BLOSUM62</mat-radio-button>
          <mat-radio-button class="option" value="BLOSUM45">BLOSUM45</mat-radio-button>
          <mat-radio-button class="option" value="BLOSUM50">BLOSUM50</mat-radio-button>
          <mat-radio-button class="option" value="BLOSUM90">BLOSUM90</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    
    <div class="Run_button">
      <button mat-raised-button style="margin: 1%;" color="primary" type="submit" name="action" (click)="onSubmit()">Run
        BLAST
      </button>
      <button *ngIf="this.result" mat-raised-button color="warn" class="btn clear waves-effect waves-light" type="reset"
        name="action" (click)="clear()">Clear Results
      </button>
      <mat-icon id="help-icon" class="pointer"
        [matTooltip]="'Input a fasta sequence to do blast. Click this icon for an example.'"
        (click)="setDefaultSearch()">help</mat-icon>
    </div>
  </mat-card>

  <mat-card style="margin-top: 1%;" *ngIf="this.showMatcard">
    <div class="row">
      <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <mat-progress-bar id="loading-bar" mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>
    <div class="output-field col s6" style="margin-top: 20px;">
      <p style="font-weight: bold; margin-bottom: 0; font-size: large;">Input Sequence</p>
      <p style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border: 1px solid #121111; font-size: 18px; margin: 0; padding: 20px; width: 100%; height: 100px; box-sizing: border-box; overflow: auto;">
        {{ proteinSeq.trim() }}
    </p>
    
    
    </div>
    
    <div   class="table-container" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; margin: 2%; overflow-x: auto;">
      <table mat-table [dataSource]="blastDataSource" class="mat-elevation-z8" *ngIf="blastDataSource">
        
        <ng-container matColumnDef="query_sequence">
          <th mat-header-cell *matHeaderCellDef> Query Sequence </th>
          <td mat-cell *matCellDef="let element"> {{element.query_sequence}} </td>
        </ng-container>
        <ng-container matColumnDef="subject_sequence">
          <th mat-header-cell *matHeaderCellDef> Subject Sequence </th>
          <td mat-cell *matCellDef="let element" class="clickable-cell" (click)="onSequenceClick(element.subject_sequence)"> {{element.subject_sequence}} </td>
        </ng-container>
        <ng-container matColumnDef="pident">
          <th mat-header-cell *matHeaderCellDef> Pident </th>
          <td mat-cell *matCellDef="let element"> {{element.pident}} </td>
        </ng-container>
        <ng-container matColumnDef="gapopen">
          <th mat-header-cell *matHeaderCellDef> Gap Open </th>
          <td mat-cell *matCellDef="let element"> {{element.gapopen}} </td>
        </ng-container>
        <ng-container matColumnDef="evalue">
          <th mat-header-cell *matHeaderCellDef> Evalue </th>
          <td mat-cell *matCellDef="let element"> {{element.evalue}} </td>
        </ng-container>
        <ng-container matColumnDef="bitscore">
          <th mat-header-cell *matHeaderCellDef> BitScore </th>
          <td mat-cell *matCellDef="let element"> {{element.bitscore}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
    </div>
  
  </mat-card > 
  <mat-progress-bar id="loading-bar" mode="indeterminate" style="margin-top: 15px;;" *ngIf="this.loadingSequence"></mat-progress-bar>
  <mat-card class="sequenceInfo" *ngIf="listOfDataPeptides.length">
    <h3 id="title">Peptide Results</h3>
    
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 demo-table">
        <!-- Expandable Column -->
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()">
            <mat-icon>{{ expandedElement === element ? 'indeterminate_check_box' : 'add_box' }}</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="sequenceDisplayedColumn.length">
          <div class="expanded_discription" *ngIf="expandedElement === element">
            <!-- Content to display when the panel is expanded -->
            <div [innerHTML]="element.description"></div>
          </div>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="detected_peptide">
        <th mat-header-cell *matHeaderCellDef> Detected Peptide </th>
        <td mat-cell *matCellDef="let element"> {{element.detected_peptide}} </td>
      </ng-container>
    
      <!-- Description Column -->
      <ng-container matColumnDef="peptide_feature">
        <th mat-header-cell *matHeaderCellDef> Peptide Features </th>
        <td mat-cell *matCellDef="let element"> {{element.peptide_feature}} </td>
      </ng-container>
    
      <!-- Detected Peptide Column -->
      <ng-container matColumnDef="peptide">
        <th mat-header-cell *matHeaderCellDef> Peptide </th>
        <td mat-cell *matCellDef="let element"> {{element.peptide}} </td>
      </ng-container>
    
      <!-- Peptide Feature Column -->
      <ng-container matColumnDef="peptide_in_protein">
        <th mat-header-cell *matHeaderCellDef> Peptide In Protein </th>
        <td mat-cell *matCellDef="let element"> {{element.peptide_in_protein}} </td>
      </ng-container>

      <ng-container matColumnDef="site_in_peptide">
        <th mat-header-cell *matHeaderCellDef> Site In Protein </th>
        <td mat-cell *matCellDef="let element"> {{element.site_in_peptide}} </td>
      </ng-container>

      <ng-container matColumnDef="internal_protein_id">
        <th mat-header-cell *matHeaderCellDef> P3DB_ID </th>
        <td mat-cell *matCellDef="let element" class="clickable-cell" (click)="onP3DB_ID_Click(element.internal_protein_id)"> {{element.internal_protein_id}} </td>
      </ng-container>

      <ng-container matColumnDef="fastaHeader">
        <th mat-header-cell *matHeaderCellDef> Locus ID </th>
        <td mat-cell *matCellDef="let element" 
        (click)="locusClicked(element.fastaHeader)"
        class="clickable-cell"> 
        {{element.fastaHeader}} 
      </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="sequenceDisplayedColumn"></tr>
      <tr mat-row *matRowDef="let row; columns: sequenceDisplayedColumn;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">

      </tr>
      <ng-container>
        
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </ng-container>
      
      
    </table>
    

  </mat-card>

  <mat-card *ngIf="phosphorylation_sites.length" style="margin-top: 10px; overflow-x: scroll;">
    <h3 id="title">Phosphorylation Site in Protein</h3>
  </mat-card>


  <mat-card *ngIf="phosphorylation_sites.length" id="title-container">
    <div class="scrooll" *ngFor="let phosphorylation_sites of phosphorylation_sites;let i = index">
      <h4 style="color:#7eb742"># Protein:{{proteinId[i]}}</h4>
      <div>
        <div style="width: 80px; display: inline-block;text-align: center;color: #548Ddf;">
          <p>Sequence</p>
        </div>
        <span *ngFor="let item of phosphorylation_sites;let i = index" [ngClass]="fn_seqHighlight(item)"
          title="{{phosphorylation_sites[i].tooptip}}">
          <a *ngIf="item.class== 'highlight'; else elseClass" data-toggle="tooltip" data-placement="top"
            title="value will be printed" (click)="getData(i,item.protien_id)">{{item.name}}</a>
          <ng-template #elseClass>
            {{item.name}}
          </ng-template>
        </span>
      </div>
      <div>
        <div style="width: 80px; display: inline-block;text-align: center;">
          <p>|</p>
        </div>
        <span *ngFor="let item of phosphorylation_sites" [ngClass]="{'seq_highlight01':'true'}">{{item.arrow}}</span>
      </div>
      <div>
        <div style="width: 80px;display: inline-block;text-align: center;color: #548Ddf;">
          <p>Position:</p>
        </div>
        <span *ngFor="let item of phosphorylation_sites" [ngClass]="{'seq_highlight01':'true'}">{{item.index}}</span>
      </div>
    </div>
  </mat-card>
  <script>
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  </script>


</mat-card>


 
