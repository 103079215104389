import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { forkJoin, queueScheduler } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-phylogenetic-tree-dataset',
  templateUrl: './phylogenetic-tree-dataset.component.html',
  styleUrls: ['./phylogenetic-tree-dataset.component.css']
})
export class PhylogeneticTreeDatasetComponent implements OnInit {

  jsonData: any;
  proteinID: String;
  dataSource: any[];
  displayedColumns: string[] = ['fasta_header', 'internal_protein_id'];
  selected_element : string;
  selected_data : string;
  requestError: string;
  speciesTitle:String;
  showWait = false;
  listOfDataPeptides=[];
  columnKeysPeptides = {
    'Detected Peptide': {"val":'detected_peptide',"tooltip":"detected_peptide"},
    'Peptide Features': {"val":'peptide_feature',"tooltip":"peptide feature"},
    Peptide: {"val":'peptide',"tooltip":"peptide"},
    'Peptide In Protein': {"val":'peptide_in_protein',"tooltip":"peptide in protein"},
    'Site In Protein': {"val":'site_in_peptide', "tooltip":"site in peptide"},
    'Protein Name': {"val":'fastaHeader',"tooltip":"protein name"},
    'P3DB ID': {"val":'internal_protein_id',"tooltip":'internal protein id'}
  };
  public pageSize: number = 5;
  public total: number = 0;
  public pageIndex: number = 1;
  public nzPageSizeOptions:Array<number>=[5,10,15,20];
  public source:any;

  constructor(private httpClient:HttpClient, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.proteinID = this.route.snapshot.params.id;
    this.getData();
    // Assign data to the dataSource property

  }

  setPagination(){
    this.total = this.jsonData.length;
    this.pageSize = 5
    this.pageIndex = 1
  }
  getRowClassName = (index) => {
    return index % 2 == 0 ? '' : 'evenRow';
  };
  nzPageSizeChange(newSize) {
    this.handlePagination(this.pageIndex, newSize)
  }
  nzPageIndexChange(newIndex) {
    this.handlePagination(newIndex, this.pageSize)
  }
  handlePagination(currentIndex, perPageNumber){
    this.jsonData = this.source.slice((currentIndex-1)* perPageNumber, currentIndex*perPageNumber);
  }

  onLinkClick(val: string) {
    this.router.navigate(["/p3dbid_" + val]);
  }

  getData() {
    //forkJoin combines the two observables from http requests and waits for both to finish
    this.showWait = true;
    forkJoin([
      this.fetchJson(environment.BASE_API_URL + 'protein_for_speciesid/?taxonomy_id='+this.proteinID),

    ]).subscribe((result) => {
      console.log("this.jsonData is : ",result[0]['data']['data']);
      this.jsonData=result[0]['data']['data'];
      this.speciesTitle = result[0]['data']['species'];
      this.dataSource = this.jsonData;
      this.source = this.jsonData;
      this.setPagination();
      this.handlePagination(this.pageIndex,this.pageSize);
      this.showWait = false;
    }, error => {
      console.log(error);
      this.requestError = error.statusText;
    });
  }

  fetchJson(filename) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    return this.httpClient.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
    }

  update_selected_row(element)
  {
    this.selected_data = element
  }
  
  dealInfo(peptides, features, paperInfo, protein_detail) {
    this.listOfDataPeptides=[]
    for (let item in peptides) {
      let internalProteinId = peptides[item].internal_protein_id;
      let feature = '';
      features[item].forEach((item) => {
        feature += item.feature_name + ' : ' + item.feature_value + '\n';
      });

      let hasArray = false;
      for (const key in protein_detail) {
        if (Array.isArray(protein_detail[key])) {
          hasArray = true;
        }
        break;
      }

      if(hasArray){
        let fasta_header = protein_detail[internalProteinId][0].fasta_header.split("|")[0]
      peptides[item].fastaHeader = fasta_header;
      peptides[item].highlightProteinName = "false";
      peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '\n';
      peptides[item].description +=
        'Fasta Header: ' + protein_detail[internalProteinId][0].fasta_header + '\n';
      peptides[item].description +=
        'External Id Type: ' + protein_detail[internalProteinId][0].external_id_type + '\n';
      peptides[item].description += 'ID: ' + protein_detail[internalProteinId][0].id + '\n';
      peptides[item].description += 'Length: ' + protein_detail[internalProteinId][0].length + '\n';
      peptides[item].description +=
        'Organism Id: ' + protein_detail[internalProteinId][0].organism_id + '\n' + '\n';
      peptides[item].description += "Paper's Information:" + '\n';
      peptides[item].description +=
        'Title:' + paperInfo[peptides[item].datasource].title + '\n';
      peptides[item].description +=
        'Link:' + paperInfo[peptides[item].datasource].link + '\n';
      peptides[item].description +=
        'Year:' + paperInfo[peptides[item].datasource].year + '\n';
      peptides[item].id = peptides[item].internal_peptide_id;
      this.listOfDataPeptides.push(peptides[item]);
      }
      else{
        let fasta_header = protein_detail.fasta_header.split("|")[0]
      peptides[item].fastaHeader = fasta_header;
      
        peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '\n';
      peptides[item].description +=
        'Fasta Header: ' + protein_detail.fasta_header + '\n';
      peptides[item].description +=
        'External Id Type: ' + protein_detail.external_id_type + '\n';
      peptides[item].description += 'ID: ' + protein_detail.id + '\n';
      peptides[item].description += 'Length: ' + protein_detail.length + '\n';
      peptides[item].description +=
        'Organism Id: ' + protein_detail.organism_id + '\n' + '\n';
      peptides[item].description += "Paper's Information:" + '\n';
      peptides[item].description +=
        'Title:' + paperInfo[peptides[item].datasource].title + '\n';
      peptides[item].description +=
        'Link:' + paperInfo[peptides[item].datasource].link + '\n';
      peptides[item].description +=
        'Year:' + paperInfo[peptides[item].datasource].year + '\n';
      peptides[item].id = peptides[item].internal_peptide_id;
      this.listOfDataPeptides.push(peptides[item]);
      }
      
      console.log("List of Data Peptides : ",this.listOfDataPeptides);
    }
  }

}