import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-count-summary',
  templateUrl: './count-summary.component.html',
  styleUrls: ['./count-summary.component.css'],
  styles: [`
    :host {
      display: block;
      width: 100%;
      height: 100%;
    }
    
    :host ::ng-deep .mat-card {
      box-shadow: none;
      border-radius: 0;
    }
  `]
})
export class CountSummaryComponent implements OnInit {
  ELEMENT_DATA = null; // Stores dataset information
  PUBMED_DATA = null; // Stores PubMed-specific data

  displayChart: boolean = false; // Controls the display of charts
  requestError: string; // Stores error messages for requests
  pubmedID: string | null; // Holds the PubMed ID from route parameters
  data: any = null; // General-purpose data holder

  columnKeysProteinIDs = {
    'P3DB ID': { val: 'p3db_id', tooltip: 'p3db ID' },
    'Protein Name': { val: 'ProteinName', tooltip: 'Protein Name' },
    'Species Name': { val: 'Species Name', tooltip: 'Species Name' },
    'Uniprot ID': { val: 'UniprotID', tooltip: 'Uniprot ID' },
    'UniParc ID': { val: 'UniParcID', tooltip: 'UniParc ID' },
    RefSeq: { val: 'RefSeq', tooltip: 'Ref Seq' },
    'Locus ID': { val: 'LocusID', tooltip: 'Locus ID' },
    'Gene Symbol': { val: 'GeneSymbol', tooltip: 'Gene Symbol' },
    'Gene ID': { val: 'GeneID', tooltip: 'Gene ID' },
    EnsemblPlants: { val: 'EnsemblPlants', tooltip: 'Ensembl Plants' },
    EMBL: { val: 'EMBL', tooltip: 'EMBL' },
    STRING: { val: 'STRING', tooltip: 'STRING' },
    'BioGR ID': { val: 'BioGRID', tooltip: 'BioGRID' },
    IntAct: { val: 'IntAct', tooltip: 'IntAct' },
    'Species ID': { val: 'SpeciesID', tooltip: 'Species ID' },
  };

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Get pubmedID from route params
    this.route.params.subscribe(params => {
      this.pubmedID = params['pubmedID'];
      if (this.pubmedID) {
        this.getPubmedData();
      } else {
        this.getData();
      }
    });
  }

  // Fetch general dataset data
  getData(): void {
    const apiUrl = environment.BASE_API_URL + 'datasets/';
    this.fetchJson(apiUrl).subscribe(
      (jsonData) => {
        // Debugging: Log the raw API response
        console.log('Raw API Response:', jsonData['data']);

        jsonData['data'].forEach((item) => {
          // Explicitly remap "inverstigator" to "Investigator"
          if (item['inverstigator']) {
            item['Investigator'] = item['inverstigator']; // Map the typo field
            delete item['inverstigator']; // Remove the typo field
          }

          // Add PubMed links and router links
          const linkName = item.PubmedID;
          item['routerLink'] = `/countSummary/${linkName}`; // Add leading slash for absolute path
          const pubmedLink = `https://pubmed.ncbi.nlm.nih.gov/${item.PubmedID}`;
          item['link'] = pubmedLink;
        });

        // Debugging: Log the API data after remapping
        console.log('After Remapping:', jsonData['data']);

        // Assign and filter dataset data by Year > 1999
        this.ELEMENT_DATA = {
          name: [
            'Inverstigator',
            'Organism',
            'OrganismID',
            'PTM',
            'PubmedID',
            'Tissue',
            'Title',
            'Year',
            'internal_peptide_id_count',
            'internal_protein_id_count',
          ],
          display: [
            'Investigator',
            'Organism',
            'Organism ID',
            'PTM',
            'Pubmed ID',
            'Tissue',
            'Title',
            'Year',
            'Peptide Counts',
            'Protein Counts',
          ],
          data: jsonData['data'].filter((item) => item.Year > 1999),
        };

        // Debugging: Log the final ELEMENT_DATA
        console.log('Filtered ELEMENT_DATA:', this.ELEMENT_DATA.data);
      },
      (error) => {
        this.requestError = error;
        console.error('Error fetching dataset data:', error); // Log error
      }
    );
  }

  // Fetch PubMed-specific data
  getPubmedData(): void {
    const apiUrl = environment.BASE_API_URL + 'proteinids_details_for_datasource/?datasource_id='+ this.pubmedID;
    console.log(apiUrl)
    this.fetchJson(apiUrl).subscribe(
      (jsonData) => {
        const apiData = jsonData['data'];

        apiData.forEach((item) => {
          const linkName = `p3dbid_${item.p3db_id}`;
          item['link'] = linkName;
          item.batchSearch = 1;
          item.isSelected = false;
        });

        // Create the proper structure for the table component
        this.PUBMED_DATA = {
          name: Object.values(this.columnKeysProteinIDs).map(item => item.val),
          display: Object.keys(this.columnKeysProteinIDs),
          data: apiData
        };

        // Debugging: Log the fetched PubMed data
        console.log('PubMed Data:', this.PUBMED_DATA);
      },
      (error) => {
        this.requestError = 'Failed to fetch PubMed-specific data.';
        console.error('Error fetching PubMed data:', error); // Log error
      }
    );
  }

  // Fetch JSON data from a given API URL
  fetchJson(apiUrl: string): Observable<any> {
    const options = { responseType: 'json' as const };

    return this.http.get(apiUrl, options).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error fetching JSON:', error); // Log fetch error
        this.requestError = 'An error occurred while fetching data.';
        return throwError(() => error);
      })
    );
  }

  // Navigate to the count summary route
  onBackClick(): void {
    const routeToNavigate = '/countSummary';
    this.router.navigate([routeToNavigate]);
  }

  // Handle navigation for P3DB ID
  handleP3DBID(obj: { val: string }): void {
    this.router.navigate([`/p3dbid_${obj.val}`]);
  }
}
