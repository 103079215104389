<br>
<mat-card id="component-container" *ngIf="!pubmedID">
  <mat-card id="title-container">
    <h1 id="title">Datasets</h1>
  </mat-card>
  <mat-card id="title-container" style="margin-top: 10px;">
    <div *ngIf="!ELEMENT_DATA && !requestError">
      <p style="text-align: center; margin: 20px 0">Fetching summary data...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div *ngIf="requestError">
      <p style="text-align: center; margin: 20px 0"><b>{{requestError}}.</b></p>
      <p style="text-align: center">Please try refreshing the page.</p>
    </div>
    
    <app-table *ngIf="ELEMENT_DATA && !requestError" [tableData]="ELEMENT_DATA"></app-table>
  </mat-card>
</mat-card>

<mat-card id="component-container" *ngIf="pubmedID">

  <mat-card id="title-container">
    <button mat-icon-button (click)="onBackClick()" id="back-button">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div id="title-container-inner">
      <h1 id="title">Pubmed ID</h1>
    </div>
  </mat-card>
  
  <mat-card id="title-container" style="margin-top: 10px;">
    <!-- <p>{{pubmedID}}</p> -->

    <div *ngIf="!PUBMED_DATA && !requestError">
      <p style="text-align: center; margin: 20px 0">Fetching summary data...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div *ngIf="requestError">
      <p style="text-align: center; margin: 20px 0"><b>{{requestError}}.</b></p>
      <p style="text-align: center">Please try refreshing the page.</p>
    </div>
    
    <!-- <div class="container"> -->

      <!-- <app-table *ngIf="PUBMED_DATA && !requestError" [tableData]="PUBMED_DATA"></app-table> -->

      <!-- <div id="test-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;" *ngIf = "PUBMED_DATA && !requestError" >
        <p3dbTable [columnKeys]="columnKeysProteinIDs" [dataSourceShow]="PUBMED_DATA"
          [searchIndex]="searchIndex" [hasCheckbox]="false" [thisTip]="'This is not a Phosophory Protien'"
          [presentInDatabase]="false" [iconTip]="'This is Phosphorylation Protein'"
          [iconHeader] = "'Is phos. protein?'"
           (handleCellClick)="handleP3DBID($event)">
        </p3dbTable>
      </div> -->


      <!-- <div *ngIf = "data">
        <div class="item" *ngFor="let item of data">
          <a class="p3dbid" [routerLink]="['/p3dbid_' + item]">{{ item }}</a>
        </div>
      </div> -->
      
  <!-- </div> -->

  </mat-card>
  


</mat-card>


<br>
