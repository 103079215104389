import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, range } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as Papa from 'papaparse';
import { F } from '@angular/cdk/keycodes';
// import { DataService } from './data.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

// import { CoseLayoutOptionsImpl } from 'cytoscape-angular';
@Component({
  selector: 'app-ptm-peptides',
  templateUrl: './ptm-peptides.component.html',
  styleUrls: ['./ptm-peptides.component.css'],
})
export class PtmPeptidesComponent implements OnInit {
  constructor(
    private http: HttpClient, 
    private msg: NzMessageService, 
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }
  type;
  proteinId = [];
  searchLabels = [
    { label: 'Sequence Search', value: '1',isLeaf: true, defaultValue:'STIGVEFATR' },
    { label: 'Protein ID Search', value: '2', children:[
      {label:'P3DB ID',value:'1',isLeaf:true,defaultValue:'1743985'},
      {label:'Locus ID', value:'2',isLeaf: true, defaultValue: 'AT3G15060'},
      {label:'UniProt ID', value:'3',isLeaf: true, defaultValue:'Q9LK99'},
      {label:'UniParc ID', value:'4',isLeaf: true, defaultValue: 'UPI000000C6AE'},
      {label:'BioGRID ID', value:'5',isLeaf: true, defaultValue: '22731'},
      {label:'EnsemblPlants ID', value:'6',isLeaf: true, defaultValue: 'AT1G09630.1'},
      {label:'EMBL ID', value:'7',isLeaf: true, defaultValue:'AY045620'},
      {label:'IntAct ID', value:'8',isLeaf: true, defaultValue:'EBI-4457675'},
      {label:'RefSeq ID', value:'9',isLeaf: true, defaultValue:'NP_172434'}],
     },
    { label: 'Gene Symbol Search', value: '4', isLeaf: true, defaultValue: 'RAB1' },
    {label: 'Protein Name Search',value:'3',isLeaf:true,defaultValue:'ras-related protein'}];
  
  listOfBlastTabularResults=[];
  listOfDataPeptides = [];
  listOfParticularProteinData=[];
  columnKeysPeptides = {
    'Detected Peptide': {'val':'detected_peptide','tooltip':'detected peptide'},
    'Peptide Features': {"val":'peptide_feature',"tooltip":"peptide feature"},
    Peptide: {"val":'peptide',"tooltip":"peptide"},
    'Peptide In Protein': {"val":'peptide_in_protein',"tooltip":"Peptide in Protein"},
    'Site In Protein': {"val":'site_in_peptide',"tooltip":"site in protein"},
    'P3DB ID': {"val":'internal_protein_id',"tooltip":'Internal protein'},
    'Locus ID': {"val":'fastaHeader',"tooltip":"protein name"}
  };
  listOfDataProteinIDs = [];
  count=0;
  columnKeysProteinIDs = {
    'P3DB ID': {"val":'p3db_id',"tooltip":"p3db ID"},
    'Protein Name': {"val":'ProteinName',"tooltip":"protein Name"},
    'Species Name': {"val":'Species Name',"tooltip":"species name"},
    'Uniprot ID': {"val":'UniprotID',"tooltip":"uniprot ID"},
    'UniParc ID': {"val":'UniParcID', "tooltip":'uni parc ID'},
    RefSeq: {"val":'RefSeq',"tooltip":"ref seq"},
    'Locus ID': {"val":'LocusID',"tooltip":"locus Id"},
    'Gene Symbol': {"val":'GeneSymbol',"tooltip":"gene symbol"},
    'Gene ID': {"val":'GeneID',"tooltip":"Gene ID"},
    EnsemblPlants: {"val":'EnsemblPlants',"tooltip":"emsembl plants"},
    EMBL: {"val":'EMBL', "tooltip":"embl"},
    STRING: {"val":'STRING',"tooltip":"string"},
    'BioGR ID': {"val":'BioGRID',"tooltip":"bio grid"},
    IntAct: {"val":'IntAct',"tooltip":"intact"},
    'Species ID': {"val":'SpeciesID',"tooltip":"specied id"}
  };
  searchIndex = 0;
  protein_details;
  PTM_PEPTIDES_DATA;
  PTM_SITES_DATA;
  showWait = false;
  isDialogVisible = false;
  SEQUENCE_DATA;
  idType='LocusID'
  nestedDealPtmSites=false
  input_query:String;
  phosphorylation_sites = [];
  csvData =[];
  temp_flag = false;
  noData = false;
  enteredValue = "";
  secondaryQueryType;
  secondaryQueryValue;
  speciesList: any[] = [];
  filteredSpecies: any[] = [];
  showPhosphorylationSites = true;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const locusId = params['locusId'];
      if (locusId) {
        this.idType='LocusID';
        // Find and update the Locus ID option within Protein ID Search
        const proteinIdSearch = this.searchLabels.find(label => label.value === '2');
        if (proteinIdSearch && proteinIdSearch.children) {
          const locusIdOption = proteinIdSearch.children.find(child => child.label === 'Locus ID');
          if (locusIdOption) {
            locusIdOption.defaultValue = locusId;
          }
        }
        this.getProteinID(locusId); // Initiate the search with locusId
      }
    });
    this.loadSpecies();
  }
  //https://p3db.ddns.net:5000/api/getptmpeptides/?locus_id=AT1G01110.2
  //https://p3db.ddns.net:5000/api/get_intproteinid_details/?int_prot_id=1743222
  getDataByProtein(val) {
    this.noData = false;
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL +
          'get_intproteinid_details/?int_prot_id=' +
          val 
      ),
    ]).subscribe((result) => {
      console.log("getDataByProtein",environment.BASE_API_URL +
        'get_intproteinid_details/?int_prot_id=' +
        val);
      
      if (!result || !result[0] || !result[0]['data']) {
        this.noData = true;
        this.showWait = false;
        return;
      }

      let data = result[0]['data'];
      
      if(!data || !data['paper_info'] || Object.entries(data['paper_info']).length === 0){
        this.noData = true;
        this.showWait = false;
        return;
      }

      let locusID = data['protein_detail']?.['fastaHeader']?.split("|")?.[0]?.trim()?.split(".")?.[0] || '';
      if (data.peptides && data.peptide_features && data.paper_info && data.protein_detail) {
        this.dealInfo(
          data.peptides,
          data.peptide_features,
          data.paper_info,
          data.protein_detail
        );

        let sites = [];
        if (data.protein_detail.ptm_sites) {
          data.protein_detail.ptm_sites.forEach((element) => {
            sites.push(element.site_in_protein);
          });
          console.log("sites : ",sites);
          this.dealPtmSites(
            sites,
            data.protein_detail.sequence,
            data.protein_detail.internal_protein_id
          );
        }
        
        console.log("locusid is : ",locusID);
        this.idType = "p3db_id";
        this.getProteinDetails(val,val);
        // Only set noData to false if we actually got data
        this.noData = false;
      } else {
        this.noData = true;
      }
      this.showWait = false;
    }, error => {
      console.error('Error fetching protein details:', error);
      this.noData = true;
      this.showWait = false;
    });
  }

  fetchJson(filename) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };
    
    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  dealPtmSites(sties, sequence, id) {
    let phosphorylation_sites = [];
    this.phosphorylation_sites = []
    let ptm_index = 0;
    let index_highLight = sties.map((element) => {
      return element - 1;
    });
    let array_seq = [...sequence];
    array_seq.forEach((element, index) => {
      if (index_highLight.includes(index)) {
        phosphorylation_sites.push({
          name: element,
          class: 'highlight',
          index: '',
          arrow: '',
          tooptip: '',
          protien_id:id
        });
        ptm_index++;
      } else {
        phosphorylation_sites.push({
          name: element,
          class: 'none',
          index: (index + 1) % 10 == 0 ? String(index + 1) : '',
          arrow: (index + 1) % 10 == 0 ? '|' : '',
          tooptip: '',
          protien_id:id
        });
      }
    });
    this.proteinId.push(id);
    this.phosphorylation_sites.push(phosphorylation_sites);
    console.log("List of Phosphorylation sites : ",this.phosphorylation_sites);
  }

  dealInfo(peptides, features, paperInfo, protein_detail) {
    this.listOfDataPeptides=[]
    for (let item in peptides) {
      let internalProteinId = peptides[item].internal_protein_id;
      let feature = '';
      if(features[item]==null){
        feature = "N/A "+'\n';
      }else{
        features[item].forEach((item) => {
          feature += item.feature_name + ' : ' + item.feature_value + '\n';
        });
      }
      
      
      let hasArray = false;
      for (const key in protein_detail) {
        if (Array.isArray(protein_detail[key])) {
          hasArray = true;
        }
        break;
      }

      if(Object.keys(protein_detail).length === 0){
        peptides[item].fastaHeader = "N/A";
      peptides[item].highlightProteinName = "false";
      peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '\n';
      peptides[item].description +=
        'Fasta Header: ' + "N/A " + '\n';
      peptides[item].description +=
        'External Id Type: ' + "N/A " + '\n';
      peptides[item].description += 'ID: ' + " N/A" + '\n';
      peptides[item].description += 'Length: ' + " N/A" + '\n';
      peptides[item].description +=
        'Organism Id: ' + " N/A" + '\n' + '\n';
      peptides[item].description += "Paper's Information:" + '\n';
      if (paperInfo[peptides[item].datasource]) {
        peptides[item].description +=
            'Title:' + (paperInfo[peptides[item].datasource].title || "N/A") + '\n';
        peptides[item].description +=
            'Link:' + (paperInfo[peptides[item].datasource].link || "N/A") + '\n';
        peptides[item].description +=
            'Year:' + (paperInfo[peptides[item].datasource].year || "N/A") + '\n';
    } else {
        peptides[item].description += 'Title: N/A\n';
        peptides[item].description += 'Link: N/A\n';
        peptides[item].description += 'Year: N/A\n';
    }
      peptides[item].id = peptides[item].internal_peptide_id;
      this.listOfDataPeptides.push(peptides[item]);
      }else{
        
        if(hasArray){
          
          let fasta_header = protein_detail[peptides[item].internal_protein_id][0].fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          }      
        peptides[item].fastaHeader = fasta_header;
        peptides[item].highlightProteinName = "false";
        peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '\n';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail[internalProteinId][0].fasta_header + '\n';
        peptides[item].description +=
          'External Id Type: ' + protein_detail[internalProteinId][0].external_id_type + '\n';
        peptides[item].description += 'ID: ' + protein_detail[internalProteinId][0].id + '\n';
        peptides[item].description += 'Length: ' + protein_detail[internalProteinId][0].length + '\n';
        peptides[item].description +=
          'Organism Id: ' + protein_detail[internalProteinId][0].organism_id + '\n' + '\n';
        peptides[item].description += "Paper's Information:" + '\n';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '\n';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '\n';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '\n';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
        else{
          let fasta_header = protein_detail.fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          } 
        peptides[item].fastaHeader = fasta_header;
        
          peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '\n';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail.fasta_header + '\n';
        peptides[item].description +=
          'External Id Type: ' + protein_detail.external_id_type + '\n';
        peptides[item].description += 'ID: ' + protein_detail.id + '\n';
        peptides[item].description += 'Length: ' + protein_detail.length + '\n';
        peptides[item].description +=
          'Organism Id: ' + protein_detail.organism_id + '\n' + '\n';
        peptides[item].description += "Paper's Information:" + '\n';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '\n';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '\n';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '\n';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
      }

      
      
      console.log("List of Data Peptides : ",this.listOfDataPeptides);
    }
  }


  search(obj) {
    this.enteredValue = obj.value;
    this.noData = false;
    // added this condition to not show the particular protein data when search with a particular search query in locus id of the ptm peptides
    if (!obj.hasOwnProperty('rowData')) {
      this.listOfParticularProteinData = [];
    }
    if (this.listOfParticularProteinData.length > 1) {
      this.listOfParticularProteinData.shift();
    }
    this.listOfDataProteinIDs = [];
    this.listOfDataPeptides = [];
    this.phosphorylation_sites = [];
    this.proteinId = [];
    this.PTM_SITES_DATA = undefined;
    this.protein_details = undefined;
    this.input_query = obj.value;
    console.log("the search value label is : " + obj.value);

    // Capture secondary query parameters
    this.secondaryQueryType = obj.secondaryQueryType;
    this.secondaryQueryValue = obj.secondaryQueryValue;

    if (obj.value) {
      this.type = obj.label;
      // Set visibility based on search type
      this.showPhosphorylationSites = obj.label !== '1'; // Hide for sequence search (label '1')
      
      switch (obj.label) {
        case '1':
          this.getDataBySequence(obj.value);
          break;
        case '2,1':
          // Redirect to intermediate page for P3DB ID search
          const url = `/p3dbid_${obj.value}`;
          this.router.navigate([url]);
          break;
        case '2,2':
          this.idType = 'LocusID';
          this.getProteinID(obj.value);
          break;
        case '2,3':
          this.idType = 'UniprotID';
          this.getProteinID(obj.value);
          break;
        case '2,4':
          this.idType = 'UniParcID';
          this.getProteinID(obj.value);
          break;
        case '2,5':
          this.idType = 'BioGRID';
          this.getProteinID(obj.value);
          break;
        case '2,6':
          this.idType = 'EnsemblPlants';
          this.getProteinID(obj.value);
          break;
        case '2,7':
          this.idType = 'EMBL';
          this.getProteinID(obj.value);
          break;
        case '2,8':
          this.idType = 'IntAct';
          this.getProteinID(obj.value);
          break;
        case '2,9':
          this.idType = 'RefSeq';
          this.getProteinID(obj.value);
          break;
        case '3':
          this.idType = "ProteinName";
          this.getProteinID(obj.value);
          break;
        case '4':
          this.idType = "GeneSymbol";
          this.getProteinID(obj.value);
      }
    } else {
      this.msg.warning('Please input protein!');
    }
  }

  handleCellClick(obj) {
    console.log("the obj is : ", obj);
    if (!obj.rowData.hasOwnProperty('highlightProteinName')) {
      this.listOfParticularProteinData.push(obj.rowData);
    }


    console.log("listOfParticularProteinData : ", this.listOfParticularProteinData);
    if (obj.col == 'internal_protein_id' || obj.col == 'p3db_id') {
      // Navigate to new page instead of searching
      const url = `/p3dbid_${obj.val}`;
      window.open(url, '_blank');
    } else if (obj.col == 'peptide' && this.type == '2') {
      this.search({ value: obj.val, label: '1', rowData: obj.rowData });
    } else {
      this.search({ value: obj.val, label: '2', rowData: obj.rowData })
    }
  }

  getProteinID(val) {
    this.noData = false;
    this.showWait = true;

    console.log('Secondary Query Type:', this.secondaryQueryType);
    console.log('Secondary Query Value:', this.secondaryQueryValue);

    // Use basic API only when secondaryQueryValue is null
    const useAdvancedApi = this.secondaryQueryValue !== null;
    
    let apiUrl = environment.BASE_API_URL;
    if (useAdvancedApi) {
      console.log('Using advanced API');
      apiUrl += 'mapping_advanced/?' + this.idType + '=' + val + '&regex=True';
      if (this.secondaryQueryType && this.secondaryQueryValue) {
        apiUrl += '&' + this.secondaryQueryType + '=' + this.secondaryQueryValue;
      }
    } else {
      console.log('Using basic API');
      apiUrl += 'mapping/?' + this.idType + '=' + val + '&regex=True';
    }

    console.log('Final API URL:', apiUrl);

    forkJoin([
      this.fetchJson(apiUrl),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      console.log("data is in protein name : ", data);
      const match = result[0]['message'].match(/\d+/);
      this.count = match ? parseInt(match[0], 10) : 0;
      if (data.length == 0) {
        console.log("Api is : ", apiUrl);
        this.showWait = false;
        this.noData = true;
      } else {
        console.log("Api is : ", apiUrl);
        this.listOfDataProteinIDs = data;
        let i = 0;
        this.listOfDataProteinIDs.forEach((item) => {
          i = i + 1;
          item.icon = item['Phosphorylation Protein(Yes/No)'];
        });
        this.showWait = false;
      }
      console.log("list of protein Id's : ", this.listOfDataProteinIDs);
    });
  }

  getDataBySequence(val) {
    // this.showWait = true;
    this.noData = false;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'get_peptide_details/?peptide=' + val
      ),
    ]).subscribe((result) => {
      console.log("API is : " + environment.BASE_API_URL + 'get_peptide_details/?peptide=' + val)
      console.log("api data is : ", result);
      let data = result[0]['data'];

      if (Object.entries(data['paper_info']).length == 0) {
        this.noData = true;
        this.showWait = false;
        // window.alert("No search results ....");
      } else {
        this.dealInfo(
          data.peptides,
          data.peptide_features,
          data.paper_info,
          data.protein_detail
        );
        for (let item in data.protein_detail) {
          let sites = [];
          data.protein_detail[item][0].ptm_sites.forEach((element) => {
            sites.push(element.site_in_protein);
          });
          this.dealPtmSites(
            sites,
            data.protein_detail[item][0].sequence,
            data.protein_detail[item][0].internal_protein_id
          );
        }
        this.showWait = false;
      }


    });
  }

  getDataBySequenceBlast(val) {
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'blastppepmatch/?peptide=' + val
      ),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      console.log("ptm blast data is :", data);
      // The below for loop is to populate the blast_tabular_results into listOfBlastTabularResults list.
      for (let element of data.blast_tabular_results) {
        this.listOfBlastTabularResults.push(element);
      }
      for (let item in data.protein_detail) {
        let sites = [];
        data.protein_detail[item][0].ptm_sites.forEach((element) => {
          sites.push(element.site_in_protein);
        });
        this.dealPtmSites(
          sites,
          data.protein_detail[item][0].sequence,
          data.protein_detail[item][0].internal_protein_id
        );
      }
      this.showWait = false;
    });
    console.log("list of data peptides : ", this.listOfDataPeptides);
  }

  dealSequenceData(protein_detail, peptides, features, paperInfo) {
    console.log("protein detail : " + protein_detail);
    for (let item in peptides) {
      let feature = '';
      features[item].forEach((item) => {
        feature += item.feature_name + ' : ' + item.feature_value + '\n';
      });

      console.log("the feature is : ", feature);
      peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '\n';
      peptides[item].description +=
        'Fasta Header: ' +
        protein_detail[peptides[item].internal_protein_id][0].fasta_header +
        '\n';
      peptides[item].description +=
        'External Id Type: ' +
        protein_detail[peptides[item].internal_protein_id][0].external_id_type +
        '\n';
      peptides[item].description +=
        'ID: ' +
        protein_detail[peptides[item].internal_protein_id][0].id +
        '\n';
      peptides[item].description +=
        'Length: ' +
        protein_detail[peptides[item].internal_protein_id][0].length +
        '\n';
      peptides[item].description +=
        'Organism Id: ' +
        protein_detail[peptides[item].internal_protein_id][0].organism_id +
        '\n' +
        '\n';
      peptides[item].description += "Paper's Information:" + '\n';
      peptides[item].description +=
        'Title:' + paperInfo[peptides[item].datasource].title + '\n';
      peptides[item].description +=
        'Link:' + paperInfo[peptides[item].datasource].link + '\n';
      peptides[item].description +=
        'Year:' + paperInfo[peptides[item].datasource].year + '\n';
      peptides[item].id = peptides[item].internal_peptide_id;
      peptides[item].length =
        protein_detail[peptides[item].internal_protein_id][0].length;
      this.listOfDataPeptides.push(peptides[item]);
      console.log("list of data peptides : ", this.listOfDataPeptides);
    }
  }

  fn_seqHighlight(item) {
    let style = {
      seq_highlight01: true,
      seq_highlight02: false,
    };
    if (item.class == 'highlight') {
      style = {
        seq_highlight01: true,
        seq_highlight02: true,
      };
    }
    return style;
  }

  refresh() {
    location.reload();
  }

  getData(site, prot_id) {
    let updated_site = site //Modified the site as it was showing the one less number than the actual as it was getting index which starts from 0.
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL +
          'similar_siteinprotein/?int_prot_id=' +
          prot_id + '&site_in_protein=' + updated_site
      ),
    ]).subscribe((result) => {

      let data = result[0]['data'];
      this.showWait = false;
      this.dealInfo(
        data.peptides,
        data.peptide_features,
        data.paper_info,
        data.protein_detail
      );
      let sites = [];
      data.protein_detail.ptm_sites.forEach((element) => {
        sites.push(element.site_in_protein);
      });
      console.log("sites : ", sites);
      this.dealPtmSites(
        sites,
        data.protein_detail.sequence,
        data.protein_detail.internal_protein_id
      );
    })
  }

  downloadData() {
    const csvData = JSON.parse(JSON.stringify(this.listOfDataPeptides));
    const removeAttrs = ['internal_peptide_id', 'internal_protein_id', 'highlightProteinName', 'id'];
    for (let obj of csvData) {

      // loop through the attributes to remove
      for (let attr of removeAttrs) {

        // delete the attribute from the object
        delete obj[attr];

      }

    }
    console.log("list of data peptides after removing attributes: ", this.listOfDataPeptides);
    console.log("list of csv data after removing attributes: ", this.csvData);

    this.downloadCSV(csvData);
  }

  downloadCSV(data: any): void {

    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  convertToCSV(data: any) {
    const csv = Papa.unparse(data);
    return csv;
  }

  close() {
    this.noData = false;
  }


  getProteinDetails(locusid, proteinid) {

    this.noData = false;
    //https://p3db.ddns.net:5000/api/mapping/?LocusID=AT1G01&regex=True
    this.showWait = true;


    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'mapping_advanced/?' + this.idType + '=' + locusid
      ),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      const match = result[0]['message'].match(/\d+/);
      this.count = match ? parseInt(match[0], 10) : 0;
      console.log("data is in protein name : ", data);
      if (data.length == 0) {
        console.log("Api is : ", environment.BASE_API_URL + 'mapping/?' + this.idType + '=' + locusid);
        this.showWait = false;
        this.noData = true;

        // window.alert("No search results ....");
      } else {
        console.log("Api is : ", environment.BASE_API_URL + 'mapping/?' + this.idType + '=' + locusid + '&regex=True');
        console.log("protein id : ", proteinid + " and data is : ", data);
        data.forEach((item) => {
          if (item.p3db_id == proteinid) {
            console.log("item is : ", item)
            this.listOfDataProteinIDs.push(item);
          }
        })

        // console.log("Data is  : ",data);
        // this.listOfDataProteinIDs = data;
        let i = 0;
        this.listOfDataProteinIDs.forEach((item) => {

          i = i + 1
          item.icon = item['Phosphorylation Protein(Yes/No)'];
        });

        this.showWait = false;
      }


      console.log("list of protein Id's : ", this.listOfDataProteinIDs);
    });
  }

  loadSpecies(): void {
    const csvPath = 'assets/species.csv';
    this.http.get(csvPath, { responseType: 'text' }).subscribe(
      (data) => {
        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            this.speciesList = result.data;
          },
          error: (err) => console.error('Error loading CSV:', err)
        });
      },
      (error) => console.error('Error fetching CSV file:', error)
    );
  }

  filterSpecies(query: string): void {
    this.filteredSpecies = this.speciesList.filter(species =>
      species['Taxonomy ID'].toLowerCase().includes(query.toLowerCase()) ||
      species['Species Name'].toLowerCase().includes(query.toLowerCase()) ||
      species['Common Name'].toLowerCase().includes(query.toLowerCase())
    );
  }

  handleSecondaryQueryInput(value: string): void {
    console.log('Secondary query input value:', value); // Debug log
    this.secondaryQueryValue = value;
    this.filterSpecies(value);
  }
}
