<mat-card id="message-container">
     <mat-card>
      <h1 id="title" style="text-align: center;"><b>AI Q&A</b></h1>
    </mat-card> 
    <mat-card style="margin-top: 10px;">
      <div class='input_protein'>
        <app-p3db-cascader-input-search id=question [labels]="searchLabels" (handleEvent)="fn_search($event)"></app-p3db-cascader-input-search>
      </div>
      <br/>
      <nz-input-group nzSearch nzSize="large">
        <input type="text" [(ngModel)]="inputValue" nz-input placeholder="input search text" id="message-input"/>
      </nz-input-group>
      <br/>
    </mat-card>
    <mat-card style="margin-top: 10px;">
      <h2>Answer is:</h2>
      <div class="information" style="overflow-x:scroll; word-wrap: break-word; white-space: pre-wrap;">{{messageContent}}</div>
    </mat-card>
</mat-card>

