<mat-card class="base">
    <div class="page-title">Datasets</div>
    <div>
        <mat-tab-group [@.disabled]="true" [selectedIndex]="0">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="tab-label">Phospho peptides summary</span>
                </ng-template>
                <div class="tab-content">
                    <app-count-summary></app-count-summary>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="tab-label">KiC Assay summary</span>
                </ng-template>
                <div class="tab-content">
                    <app-kic-papers></app-kic-papers>
                </div>
            </mat-tab>   
        </mat-tab-group>
    </div>
</mat-card>