<nz-table class="table-component" #borderedTable nzBordered [nzData]="dataSourceShow" [nzShowPagination]="false">
  <thead>
    <tr class="header-row">
      
      <th *ngIf="hasCheckbox"> <mat-checkbox [checked]="masterCheckboxChecked" (change)="selectAllChange($event)"> Select all</mat-checkbox></th> 
      <th *ngIf="hasCheckbox">Original Query ID</th>
      <th *ngIf="expandable"></th>
      <!-- optional argument here for table header  -->
      <th *ngIf="hasIcon || presentInDatabase">{{iconHeader}}</th>
      <th *ngIf="hasIcon"></th>
      <th nzCustomFilter *ngFor="let col of objectKeys(columnKeys);let index = index">
        <nz-filter-trigger *ngIf="index == searchIndex" [(nzVisible)]="visible" [nzActive]="searchValue.length > 0"
          [nzDropdownMenu]="menu">
          <span nz-icon nzType="search"></span>
        </nz-filter-trigger>
        {{col}}
        <mat-icon style="font-size: small;" [matTooltip]="columnKeys[col]?.tooltip" matTooltipPosition="after">help
        </mat-icon>
      </th>
      <!-- <ng-container *ngFor="let data of dataSourceShow">
        <th *ngIf="data.hasOwnProperty('icon')&&data.hasOwnProperty('p3db_id')&&data[icon]==true">
          ChatGPT
        </th>
      </ng-container> -->

    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of dataSourceShow;let index = index">
      <tr [class]="getRowClassName(index)">
        
        <td *ngIf="hasCheckbox">
          <mat-checkbox [checked]="data.isSelected" (change)="onCheckboxChange($event, data)">
            Select
          </mat-checkbox>
        </td>
        <td *ngIf="hasCheckbox" > {{data.input_value}}</td>
        <td *ngIf="expandable" [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)">
        </td>
        <!-- This If condition is to change the icon based on the icon data in the api-->
        <td *ngIf="data.icon==1 || data.isPresentinDatabase ==1">
          <span nz-tooltip [nzTooltipTitle]="iconTip">
            <i nz-icon nzType="check-circle" nzTheme="fill" style="color:#7eb742"></i>
          </span>
        </td>
        <td *ngIf="data.icon==0 || data.isPresentinDatabase==0">
          <span nz-tooltip [nzTooltipTitle]="thisTip">
            <i nz-icon nzType="close-circle" nzTheme="fill" style="color:#ff0000"></i>
          </span>
        </td>
        <td *ngIf="data.hasOwnProperty('icon')&&data.hasOwnProperty('p3db_id')">
          <button type="button" class="animated-button"
            (click)="openDialog(data[columnKeys['Protein Name']['val']])">askGPT</button>
        </td>

        <ng-container *ngFor="let col of objectKeys(columnKeys);let index = index">
          <!-- <td *ngIf="index==0&&data.hasOwnProperty('subject_id')&&data.hasOwnProperty('internal_protein_id'); else tempElse" class="hoverClass" 
          (click)="cellClick(columnKeys[col], data[columnKeys[col]])"> {{ data[columnKeys[col]] }} </td> 
          <ng-template #tempElse></ng-template> -->
          <!--First If condition is to make the protein id of the protein ID's table make clickable-->
          <!-- <td *ngIf = "(columnkeys[col] === 'internal_protein_id' && data.hasOwnProperty('highlightProteinName')); else dummy" class="hoverClass" 
          (click) = "cellClick(columnKeys[col], data[columnKeys[col]], data)"> {{ data[columnKeys[col]] }} <mat-icon class="hoverClass">launch </mat-icon> </td> -->
          <td *ngIf="columnKeys[col]['val']=='fastaHeader'; else baseElse" class="hoverClass"
             (click)="navigateToLink(data[columnKeys[col]['val']])">{{ data[columnKeys[col]['val']] }}</td>
          <ng-template #baseElse>
            <td *ngIf="columnKeys[col]['val']=='internal_protein_id'; else dummy"
              class="hoverClass" (click)="cellClick(columnKeys[col]['val'], data[columnKeys[col]['val']],data)">{{
              data[columnKeys[col]['val']] }}<mat-icon class="external-link">launch</mat-icon></td>
            <ng-template #dummy>
              <td
                *ngIf="(index==0&&data.hasOwnProperty('icon')&&data.hasOwnProperty('p3db_id')) || (index==0&&data.hasOwnProperty('subject_id')&&data.hasOwnProperty('internal_protein_id')) ||index==0&& data.hasOwnProperty('batchSearch') ; else elseFor"
                class="hoverClass" (click)="cellClick(columnKeys[col]['val'], data[columnKeys[col]['val']], data)"> {{
                data[columnKeys[col]["val"]] }} </td>
              <ng-template #elseFor>
                <!--This If condition is to change the format of the site_in_peptide column of Phosphorylation Peptides in Protein table -->
                <td *ngIf="checkCondition(data,index,'site_in_peptide'); else elseSite">{{formatValue(data)}}</td>
              </ng-template>
              <ng-template #elseSite>
                <td>{{data[columnKeys[col]['val']]}}</td>
              </ng-template>
            </ng-template>
          </ng-template>  
        </ng-container>
        <!-- This row id -->

      </tr>
      <tr [nzExpand]="expandSet.has(data.id)">
        <span>{{ data.description }}</span>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<nz-pagination class="iPagination" [nzShowTotal]="totalTemplate" [(nzPageIndex)]="pageIndex" [nzTotal]="total"
  [nzPageSizeOptions]="nzPageSizeOptions" [(nzPageSize)]="pageSize" nzShowSizeChanger
  (nzPageSizeChange)="nzPageSizeChange($event)" (nzPageIndexChange)="nzPageIndexChange($event)"></nz-pagination>
<ng-template #totalTemplate let-total> Total {{ total }} items </ng-template>
<nz-dropdown-menu #menu>
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>