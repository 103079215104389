// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BASE_API_URL:"https://dev.p3db.org/api.dev.2/",
  BASE_API_URL: "https://p3db.ddns.net:5004/api/",
  X_API_KEY : '8b4c7f8047ad1efde0bf2f60f6990207094e705017bff89e63baf72ffee74b76'
};

/*
 * For easier debugging in development mode, you can import the follsowing file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
