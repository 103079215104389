<!-- <nz-input-group nzSearch nzAddOnBefore="Http://" nzAddOnAfter="suffixButton">
    <input type="text" nz-input [(ngModel)]="inputValue" />
    <ng-template #suffixButton>
        <button nz-button nzType="primary" nzSize="large" nzSearch>Search</button>
      </ng-template>
  </nz-input-group> -->

<nz-input-group nzSearch nzSize="large" [nzAddOnBefore]="addOnBeforeTemplate" [nzAddOnAfter]="suffixButton">
    <input type="text" [(ngModel)]="inputValue" nz-input [style.width]="inputWidth" />
</nz-input-group>
<ng-template #addOnBeforeTemplate>
    <!-- <nz-select [(ngModel)]="currentLabel" [nzOptions]="labels">
        <nz-option *ngFor="let label of labels" [nzLabel]="label" [nzValue]="label.label"></nz-option>
    </nz-select> -->
    <nz-cascader [nzBackdrop]="False" [style]="{width: width}" [nzOptions]="labels" [(ngModel)]="currentLabel" [nzMenuStyle]="{'background-color': '#7eb742', marginTop: '38px' }" [nzAllowClear]="false" class="cascader" (nzSelectionChange)="onLabelChange()">
    </nz-cascader>
</ng-template>
<ng-template #suffixButton>
    <button nz-button nzType="primary" class="search_button" nzSize="large" nzSearch (click)="handle()">Search</button>
</ng-template>

<div *ngIf="isProteinNameSearch" style="display: flex; flex-direction: column;">
    <div style="display: flex; align-items: center;">
        <nz-select [(ngModel)]="secondaryQueryType" [nzOptions]="secondaryQueryOptions" style="margin-right: 8px; width: 150px;">
            <nz-option *ngFor="let option of secondaryQueryOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
        </nz-select>
        <div style="position: relative; flex-grow: 1;">
            <input type="text" 
                   [(ngModel)]="secondaryQueryValue" 
                   (input)="onSecondaryQueryInput($event.target.value)"
                   (focus)="onSecondaryQueryInput(secondaryQueryValue)"
                   nz-input 
                   placeholder="Search by Species ID, Name, or Common Name" 
                   style="width: 100%;" />
            
            <!-- Autocomplete Dropdown -->
            <div *ngIf="showSpeciesDropdown && filteredSpecies.length > 0" 
                 class="species-dropdown">
                <div *ngFor="let species of filteredSpecies" 
                     class="species-item"
                     (click)="selectSpecies(species)">
                    <div class="species-id">{{species.taxonomyId}}</div>
                    <div class="species-details">
                        <div class="species-name">{{species.speciesName}}</div>
                        <div class="species-common-name">{{species.commonName}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>