<mat-card id="component-container" style="padding-bottom: 1px;">
    <mat-card>
        <h1 id="title">Batch Search</h1>
    </mat-card>
    <mat-card class='input_card'>
        <div class="dropdown-container">
            <label for="idTypeSelect" style="margin-right: 10px;"><b>Enter type of ID:</b></label>
            <mat-form-field appearance="fill">
            <mat-label> ID Type</mat-label>
            <mat-select id="idTypeSelect"  [(value)]="idType" (selectionChange)="onIdTypeChange($event)">
                <mat-option value="LocusID">Locus ID</mat-option>
                <mat-option value="UniprotID">Uniprot ID</mat-option>
                <mat-option value="UniParcID">UniParc ID</mat-option>
                <mat-option value="BioGRID">BioGR ID</mat-option>
                <mat-option value="p3db_id">P3DB ID</mat-option>
            </mat-select>
            </mat-form-field>

        </div>
        <mat-form-field appearance="outline" style="margin-left: 10px;" style="width: 100%; height: auto;">
            <mat-label>Input Sequence</mat-label>
            <textarea matInput name="id_input" [(ngModel)]="id_sequence"></textarea>
        </mat-form-field>
        <div class="note">
            <p style="text-align: left;">Note: Please enter the data separated by commas.</p>
            <p style="text-align: left;"> Proteins are limited to 10 at most.</p>
        </div>
        <button mat-button class="button_search" (click)="getData();">
            SEARCH
        </button>

    </mat-card>
    <mat-card *ngIf="showWait" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
        <nz-spin *ngIf="showWait" nzSimple [nzSize]="'large'"></nz-spin>
    </mat-card>
    <mat-card *ngIf="listOfDataProteinIDs.length" style="margin-top: 10px; overflow-x: scroll;">
        <h3 id="title">
            <button (click)="downloadData()" class="styleButton">Download CSV</button>
            <button (click)="downloadFasta()" class="styleButton" style="margin-left: 10px;">Download FASTA</button>
        </h3>
    </mat-card>
    <mat-card *ngIf="listOfDataProteinIDs.length" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
        <p3dbTable [columnKeys]="columnKeysProteinIDs" [dataSourceShow]="listOfDataProteinIDs"
            [searchIndex]="searchIndex" [hasCheckbox]="true" [thisTip]="'This is not a Phosophory Protien'"
            [presentInDatabase]="true" [iconTip]="'This is Phosphorylation Protein'"
            [iconHeader] = "'Is phos. protein?'"
            (getProteinIds)="handleProteinIds($event)" (handleCellClick)="handleP3DBID($event)">
        </p3dbTable>
    </mat-card>
    <mat-card *ngIf="particularProteinData.length" id="title-container" style="margin-top: 10px; overflow-x: scroll;text-align: center;">
        <p3dbTable [columnKeys]="columnKeysProteinIDs" [dataSourceShow]="particularProteinData"
            [searchIndex]="searchIndex" [thisTip]="'This is not a Phosophory Protien'"
            [iconTip]="'This is Phosphorylation Protein'" [hasIcon]="true"
            (handleCellClick)="handleP3DBID($event)"></p3dbTable>
    </mat-card>


      
</mat-card>