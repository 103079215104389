import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-count-summary',
  templateUrl: './count-summary.component.html',
  styleUrls: ['./count-summary.component.css'],
})
export class CountSummaryComponent implements OnInit {

  ELEMENT_DATA = null;
  PUBMED_DATA = null;
  
  displayChart: boolean = false;
  requestError: string;
  pubmedID: string | null;
  data: any = null;

  columnKeysProteinIDs = {
    'P3DB ID': { "val": 'p3db_id', "tooltip": "p3db ID" },
    'Protein Name': { "val": 'ProteinName', "tooltip": "protein Name" },
    'Species Name': { "val": 'Species Name', "tooltip": "species name" },
    'Uniprot ID': { "val": 'UniprotID', "tooltip": "uniprot ID" },
    'UniParc ID': { "val": 'UniParcID', "tooltip": 'uni parc ID' },
    RefSeq: { "val": 'RefSeq', "tooltip": "ref seq" },
    'Locus ID': { "val": 'LocusID', "tooltip": "locus Id" },
    'Gene Symbol': { "val": 'GeneSymbol', "tooltip": "gene symbol" },
    'Gene ID': { "val": 'GeneID', "tooltip": "Gene ID" },
    EnsemblPlants: { "val": 'EnsemblPlants', "tooltip": "emsembl plants" },
    EMBL: { "val": 'EMBL', "tooltip": "embl" },
    STRING: { "val": 'STRING', "tooltip": "string" },
    'BioGR ID': { "val": 'BioGRID', "tooltip": "bio grid" },
    IntAct: { "val": 'IntAct', "tooltip": "intact" },
    'Species ID': { "val": 'SpeciesID', "tooltip": "specied id" }
  };

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.pubmedID = params.get('pubmedID');
    });

    if (!this.pubmedID) {
      this.getData();
    }
    else {
      this.getPubmedData();

    }
  }

  // ngOnChanges(): void {
  //   console.log('changes');
  // }

  getData() {
    this.fetchJson(environment.BASE_API_URL + 'datasets/')
      .subscribe(
        jsonData => {
          for (let i = 0; i < jsonData['data'].length; i++ )
            {
              let linkName = jsonData['data'][i].PubmedID;
              jsonData['data'][i]['routerLink'] = linkName;
              let pubmedLink = "https://pubmed.ncbi.nlm.nih.gov/" + jsonData['data'][i]['PubmedID'];
              jsonData['data'][i]['link'] = pubmedLink;
            }

          this.ELEMENT_DATA = {
            name: ["Inverstigator", "Organism", "OrganismID", "PTM", "PubmedID", "Tissue", "Title", "Year", "internal_peptide_id_count", "internal_protein_id_count"],
            display: ["Investigator", "Organism", "Organism ID", "PTM", "Pubmed ID", "Tissue", "Title", "Year", "Peptide Counts", "Protein Counts"],
            data: jsonData['data'],
          };
          // console.log(this.ELEMENT_DATA);
        },
        error => {
          this.requestError = error;
        }
      );
  }

  getPubmedData()
  {
    // deployment version
    // let api_url = environment.BASE_API_URL + 'proteinids_details_for_datasource/' + '?datasource_id=' + this.pubmedID
    
    // production version
    let api_url = "https://p3db.ddns.net:5004/api/" + 'proteinids_details_for_datasource/' + '?datasource_id=' + this.pubmedID



    // console.log(api_url)
    this.fetchJson(api_url)
      .subscribe(
        jsonData => {
          let api_data = jsonData['data'];
          // console.log(jsonData);

          for (let i = 0; i < api_data.length; i++ )
            {
              let linkName = "p3dbid_" + api_data[i].p3db_id;
              api_data[i]['link'] = linkName;
            }

          this.PUBMED_DATA = api_data;

          this.PUBMED_DATA.forEach((item) => {
            // item.isPresentinDatabase = item['Phosphorylation Protein(Yes/No)']
            item.batchSearch = 1;
            item.isSelected = false;
          });
          // console.log(this.PUBMED_DATA)
        },
        error => {
          this.requestError = error;
        }
      );
  }

  fetchJson(filename: string): Observable<any> {
    let headers = new HttpHeaders();
    const options = {
      responseType: 'json' as const,
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      }),
      catchError((error) => {
        console.error('Error fetching JSON:', error);
        this.requestError = 'An error occurred while fetching data';
        return throwError(() => error);
      })
    );
  }

onBackClick()
{
    const routeToNavigate = '/countSummary'; 
    this.router.navigate([routeToNavigate]);
}

handleP3DBID(obj){
   
  this.router.navigate(["/p3dbid_"+obj.val]);

}

}